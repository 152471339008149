// @flow
import qs from "qs";
import url from "url";

const setQueryStringWithoutPageReload = (qsValue) => {
  const newurl = url.format({
    ...window.location,
    search: qsValue,
  });
  window.history.pushState({ path: newurl }, "", newurl);
};

const sanitizeQueryString: (queryString: string) => string = (queryString) => queryString.substring(1);

export const setQueryStringValue: (
  key: string,
  value: mixed,
  queryString?: string
) => void = (key, value, queryString = window.location.search) => {
  const values = qs.parse(sanitizeQueryString(queryString));
  const newQsValue = qs.stringify(
    { ...values, [key]: value },
    { encode: false }
  );

  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const getQueryStringValue: (
  string,
  defaultValue?: mixed,
  queryString?: string
) => any = (key, defaultValue, queryString = window.location.search) => {
  const values: Object = qs.parse(sanitizeQueryString(queryString));
  if (values[key]) return values[key];

  return defaultValue;
};
