// @flow

import { getQueryStringValue, setQueryStringValue } from "./queryParamsHelper";

const availableQueryFilterKeys: string[] = [
  "invoiceId",
];

export function setQueryFilter(filters: Object) {
  setQueryStringValue("filters", filterObject(filters, availableQueryFilterKeys));
}

export function getQueryFilter(): Object {
  return filterObject(getQueryStringValue("filters", {}), availableQueryFilterKeys);
}

function filterObject(object: Object, keys: string[]): Object {
  return Object.keys(object).reduce((acc: Object, key: string): Object => {
    const isValue: boolean = object[key] || object[key] === 0 || object[key] === false;
    if (keys.includes(key) && isValue) {
      return {
        ...acc,
        [key]: object[key],
      };
    }
    return acc;
  }, {});
}
