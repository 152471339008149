// @flow
/* eslint-disable import/max-dependencies */
import React, {
  useState,
  useEffect,
  type StatelessFunctionalComponent,
  type Element,
  type Node,
} from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
  type Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  FileCopyOutlined,
  Visibility,
} from "@mui/icons-material";
import {
  FilterBuilder,
} from "@fas/cpa-cabinet-ui";
import { BRANDS_TABLE } from "../../helpers/constants";
import {
  TableComponent,
  TablePagination,
} from "../TableComponent";
import CopyToClipboard, {
  type ChildProps as CopyToClipboardChildrenProps,
} from "../CopyToClipboard";
import BannerDialog from "./BannerDialog";
import type { Column } from "../TableComponent/types/TableComponent.types";
import type { ActiveBrandsPayloadItem } from "../../containers/ActiveBrands/types/ActiveBrands.types";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import type { Props, Classes, UseState } from "./types/ActiveBrands.types";

const FilterWrapper: StatelessFunctionalComponent<*> = ({ children }) => (
  <Box display="inline-block" pr={1} pb={1}>{children}</Box>
);

const useStyles: () => Classes = makeStyles((theme: Theme): Classes => ({
  button: {
    textTransform: "capitalize",
    color: "#000",
  },
  label: {
    color: theme.palette.text.title,
  },
  input: {
    width: "253px",
  },
  headerCell: {
    color: theme.palette.primary.main,
  },
}));

const ActiveBrands: StatelessFunctionalComponent<Props> = ({
  data,
  page,
  total,
  pageSize,
  filters,
  verticalsDropdown,
  domainsDropdown,
  nichesDropdown,
  platformsDropdown,
  loading,
  dropdownLoading,
  onChangeTablePage,
  onChangeTablePageSize,
  onChangeTableFilters,
  onGetActiveBrandsData,
  onGetVerticalsDropdownData,
  onGetNichesDropdownData,
  onGetPlatformsDropdownData,
  onGetDomainsDropdownData,
}: Props) => {
  const [opened, setOpened]: UseState<boolean> = useState(false);
  const [dialogBanner, setDialogBanner]: UseState<string> = useState("");
  const [dialogTitle, setDialogTitle]: UseState<string> = useState("");

  const classes: Classes = useStyles();

  useEffect(() => {
    onGetActiveBrandsData();
  }, [onGetActiveBrandsData, page, pageSize]);

  useEffect(() => {
    onGetVerticalsDropdownData();
    onGetNichesDropdownData();
    onGetPlatformsDropdownData();
    onGetDomainsDropdownData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCopyUrl: (value: string) => void = (value) => {
    navigator.clipboard.writeText(value);
  };

  const handleOpenBannerDialog: (row: ActiveBrandsPayloadItem) => void = ({ image, domain }) => {
    setDialogBanner(image);
    setDialogTitle(domain);
    setOpened(true);
  };

  const handleCloseBannerDialog: () => void = () => {
    setOpened(false);
    setDialogBanner("");
    setDialogTitle("");
  };

  const columns: Array<Column<ActiveBrandsPayloadItem>> = [
    {
      field: "domain",
      label: "Brand name",
      key: "title",
    },
    {
      field: "vertical",
      label: "Vertical",
      key: "vertical",
    },
    {
      field: "niche",
      label: "Niche",
      key: "niche",
    },
    {
      field: "platform",
      label: "Platform",
      key: "platform",
    },
    {
      field: "url",
      label: "URL",
      key: "url",
      render: ({ row }): Node => (
        <CopyToClipboard
          copy={(): void => handleCopyUrl(row.url)}
        >
          {({ copy }: CopyToClipboardChildrenProps): Element<Button> => (
            <Button
              variant="text"
              size="small"
              startIcon={<FileCopyOutlined />}
              onClick={copy}
              className={classes.button}
              data-testid="copy-btn"
            >
              Copy
            </Button>
          )}
        </CopyToClipboard>

      ),
    },
    {
      field: "image",
      label: "Banner",
      key: "image",
      render: ({ row }): Node => (
        <Button
          variant="text"
          size="small"
          startIcon={<Visibility />}
          onClick={(): void => handleOpenBannerDialog(row)}
          className={classes.button}
          data-testid="banner-dialog-open-btn"
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box width={1} pb={2}>
          <Typography variant="h5">Active Brands</Typography>
        </Box>
        <Box display="flex">
          <Box flexGrow={1}>
            {/* FIXME: fix filters and onFiltersChange types */}
            <FilterBuilder
              // $FlowFixMe
              filters={filters}
              // $FlowFixMe
              onFiltersChange={(newFilters): $PropertyType<Props, "onChangeTableFilters"> => onChangeTableFilters(BRANDS_TABLE, newFilters)}
              WrapperItemComponent={FilterWrapper}
              items={[
                {
                  type: "select",
                  filterKey: "domain",
                  filterProps: {
                    label: "Domain name",
                    className: classes.input,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    loading: dropdownLoading,
                    "data-testid": "filter-select-domain",
                    options: [
                      { value: "", title: "All" },
                      ...domainsDropdown.map(({ label }: DropDownObjItemType): { value: string, title: string } => ({
                        value: label,
                        title: label,
                      })),
                    ],
                  },
                },
                {
                  type: "select",
                  filterKey: "vertical",
                  filterProps: {
                    label: "Vertical",
                    className: classes.input,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    loading: dropdownLoading,
                    "data-testid": "filter-select-vertical",
                    options: [
                      { value: "", label: "All" },
                      ...verticalsDropdown,
                    ]
                      .map(({ value, label }: DropDownObjItemType): { value: string, title: string } => ({
                        value,
                        title: label,
                      })),
                  },
                },
                {
                  type: "select",
                  filterKey: "niche",
                  filterProps: {
                    label: "Niche",
                    className: classes.input,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    loading: dropdownLoading,
                    "data-testid": "filter-select-niche",
                    options: [
                      { value: "", label: "All" },
                      ...nichesDropdown,
                    ]
                      .map(({ value, label }: DropDownObjItemType): { value: string, title: string } => ({
                        value,
                        title: label,
                      })),
                  },
                },
                {
                  type: "select",
                  filterKey: "platform",
                  filterProps: {
                    label: "Platform",
                    className: classes.input,
                    classes: { formLabel: classes.label },
                    disabled: loading,
                    loading: dropdownLoading,
                    "data-testid": "filter-select-platform",
                    options: [
                      { value: "", label: "All" },
                      ...platformsDropdown,
                    ]
                      .map(({ value, label }: DropDownObjItemType): { value: string, title: string } => ({
                        value,
                        title: label,
                      })),
                  },
                },
              ]}
            />
          </Box>
          <Box
            width="220px"
            pt={2}
          >
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={onGetActiveBrandsData}
            >
              Apply filters
            </Button>
          </Box>
        </Box>
        { (loading || dropdownLoading) && (
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
        { !data.length && !loading && (
          <Grid container justifyContent="center">
            <Typography>Nothing to show</Typography>
          </Grid>
        )}
        { data.length > 0 && !loading && (
          <>
            <TableComponent
              classes={{ headerCell: classes.headerCell }}
              data={data}
              columns={columns}
            />
            <TablePagination
              page={page}
              pageSize={pageSize}
              count={total}
              onChangePage={(newPage: number) => {
                onChangeTablePage(BRANDS_TABLE, newPage);
              }}
              onChangePageSize={(newPageSize: number) => {
                onChangeTablePage(BRANDS_TABLE, 1);
                onChangeTablePageSize(BRANDS_TABLE, newPageSize);
              }}
            />
            <BannerDialog
              open={opened}
              banner={dialogBanner}
              title={dialogTitle}
              handleClose={handleCloseBannerDialog}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default ActiveBrands;
