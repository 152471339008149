// @flow
import React, {
  type Node,
} from "react";
import { Table, TableBody, TableContainer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Head from "./TableHead";
import Row, { TotalRow } from "./TableRow";
import type {
  TableProps,
  TableClasses,
} from "./types/TableComponent.types";

const useStyles: (props: {[key: string]: number}) => TableClasses = makeStyles((): { [className: string]: *, ... } => ({
  table: {},
  stickyHeader: {
    maxHeight: ({ stickyTableSize }: {[key: string]: number}): string => `${stickyTableSize}vh`,
    overflowY: "auto",
  },
}));

function TableComponent<T, D>({
  columns,
  data,
  isStickyHeader = false,
  stickyTableSize = 0,
  onChangeSorting,
  sorting,
  totalColumns,
  totalData,
  classes = {},
}: TableProps<T, D>): Node {
  const ownClasses: TableClasses = useStyles({ stickyTableSize });

  return (
    <TableContainer className={clsx({
      [ownClasses.stickyHeader]: isStickyHeader && stickyTableSize,
      [classes.stickyHeader || ""]: isStickyHeader && stickyTableSize,
    })}
    >
      <Table className={clsx(ownClasses.table, classes.table)} stickyHeader={isStickyHeader}>
        <Head
          columns={columns}
          onChangeSorting={onChangeSorting}
          sorting={sorting}
          classes={classes}
        />
        <TableBody>
          {data.map((row: T, index: number): Node => (
            <Row
              classes={classes}
              index={index}
              columns={columns}
              row={row}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
          ))}
          {
            totalColumns && totalData && (
              <TotalRow
                columns={totalColumns}
                row={totalData}
              />
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableComponent;
