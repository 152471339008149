// @flow
import React, { type Node } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box, Link, Typography,
} from "@mui/material";
import { makeStylesTyped } from "../../helpers/generators";

const useStyles = makeStylesTyped((theme) => ({
  footer: {
    textAlign: "center",
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    position: "fixed",
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    borderTop: `solid 1px ${theme.palette.divider}`,
    padding: theme.spacing(1),
  },
  policy: {
    paddingRight: theme.spacing(1),
  },
  terms: {
    paddingRight: theme.spacing(1),
  },
  copyright: {},
}));

function Footer(): Node {
  const classes = useStyles();
  return (
    <Box className={classes.footer}>
      <Typography className={classes.copyright} variant="body2">Copyright © TOGETHERADS PTE. LTD, 2019</Typography>
      <Box>
        <Link underline="none" variant="body2" className={classes.policy} component={RouterLink} to="/privacy">Privacy Policy</Link>
        <Link underline="none" variant="body2" className={classes.terms} component={RouterLink} to="/terms">Terms of Use</Link>
      </Box>
    </Box>
  );
}
export default Footer;
