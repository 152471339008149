// @flow
import {
  call, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { setTableData, setTableTotalData } from "@fas/cpa-state-manager/redux/actions";
import { GET_DASHBOARD_DATA_SAGA } from "@fas/cpa-state-manager/redux/constants";
import { getTableFilters } from "@fas/cpa-state-manager/services/selectors/table";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading/actions";
import { type Fields, getDashboard } from "../../services/dashboardApi";
import { formatDateByGroup, getGroupByDateRange, prepareFilters } from "../../helpers/generators";
import { DASHBOARD_TABLE } from "../../helpers/constants";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("getDashboardDataLoading", true));
    const filters = yield select(getTableFilters, DASHBOARD_TABLE);
    const fields: Fields[] = [// date, performer, customer, department, country, platform, age, invoiceId, invoiceNumber, gender
      "date",
    ];
    const group = {
      date: getGroupByDateRange(filters.dateFrom, filters.dateTo),
      department: false,
    };
    const response = yield call(getDashboard, {
      filters: prepareFilters(filters),
      fields,
      group,
      limit: 100,
    });
    const data = response.data.result.map((item) => ({ ...item, date: formatDateByGroup(item.date, group.date) }));
    yield put(setTableData(DASHBOARD_TABLE, data));
    yield put(setTableTotalData(DASHBOARD_TABLE, response.data.total.result));
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("getDashboardDataLoading", false));
  }
}

export default function* watchGetDashboardDataSaga(): Saga<void> {
  yield takeEvery(GET_DASHBOARD_DATA_SAGA, (makeFetch: Function));
}
