// @flow
import {
  GET_ACTIVE_BRANDS_SAGA,
} from "../../helpers/constants";

export type GetActiveBrandsSaga = {
  type: typeof GET_ACTIVE_BRANDS_SAGA,
}

export type Actions = GetActiveBrandsSaga

export const getActiveBrandsSaga: () => GetActiveBrandsSaga = () => ({
  type: GET_ACTIVE_BRANDS_SAGA,
});
