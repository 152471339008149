// @flow
import {
  Record,
  Map,
  List,
} from "immutable";
import type {
  RecordFactory,
  RecordOf,
  Map as MapType,
  List as ListType,
} from "immutable";
import {
  SET_DICTIONARY,
} from "../../helpers/constants";
import type {
  Actions,
  SetDictionary,
} from "../../actions/dictionaries";

export type Dictionaries = "vertical" | "niche" | "platform" | "productCompany" | "department" | "country" | "ageGroup" | "departmentsByMarketingCompany" | "brand";

export type DropDownObjItemType = {
  label: string,
  value: string,
};

export type DropDownObjType = {
  [key: Dictionaries]: Array<DropDownObjItemType>
}

export type DropDownType = MapType<Dictionaries, ListType<MapType<string, string>>>;

export type DefaultStateType = {
  dropdownLists: DropDownType,
}

const defaultState: DefaultStateType = {
  dropdownLists: Map({}),
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordOf<DefaultStateType>;

export const initialState: State = makeState({});

export function initState(init: DropDownObjType = {}): State {
  const initImmutable: DropDownType = Object.keys(init)
    .reduce((acc: DropDownType, key: Dictionaries): DropDownType => {
      const dictionaryItemsList: ListType<MapType<string, string>> = List(
        init[key].map((payloadItem: DropDownObjItemType): MapType<string, string> => Map(payloadItem))
      );
      return acc.set(key, dictionaryItemsList);
    }, Map());

  return initialState.set("dropdownLists", initImmutable);
}

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_DICTIONARY: {
      const { payload }: SetDictionary = action;

      return state.withMutations((newState: State) => {
        Object
          .keys(payload)
          .forEach((key: Dictionaries) => {
            const dictionaryItemsList: ListType<MapType<string, string>> = List(
              payload[key].map((payloadItem: DropDownObjItemType): MapType<string, string> => Map(payloadItem))
            );
            newState.setIn(["dropdownLists", key], dictionaryItemsList);
          });
      });
    }
    default:
      return state;
  }
};
