// @flow
import type { Saga } from "redux-saga";
import {
  put,
  call,
  takeEvery, select,
} from "redux-saga/effects";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading/actions";
import { GET_DICTIONARY_SAGA } from "../../helpers/constants";
import { setDictionary, type GetDictionarySaga } from "../../actions/dictionaries";
import { getDropdownList, type Data } from "../../services/dictionariesApi";
import type { DropDownObjType, DropDownObjItemType } from "../../reducers/dictionaries";
import { getDictionary } from "../../selectors/dictionaries";

export function* makeFetch(action: GetDictionarySaga): Saga<void> {
  try {
    yield put(setLoading("getDropdownListLoading", true));

    const { dictionary, filter, performer }: GetDictionarySaga = action;

    const dicList: DropDownObjItemType[] = yield select(getDictionary, dictionary);
    if (dicList.length > 0) { // do not take if we already have
      return;
    }

    const {
      data: {
        data,
      },
    }: { data: Data } = yield call(getDropdownList, dictionary, filter, performer);

    let list = data;

    if (dictionary === "productCompany") {
      list = data.map(({ label }) => ({ label, value: label }));
    }

    const payload: DropDownObjType = {
      [`${dictionary}`]: list,
    };

    yield put(setDictionary(payload));
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("getDropdownListLoading", false));
  }
}

export default function* watch(): Saga<void> {
  yield takeEvery(GET_DICTIONARY_SAGA, (makeFetch: Function));
}
