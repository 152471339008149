// @flow
import qs from "qs";
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import type {
  Filters,
  Sorting,
} from "@fas/cpa-state-manager/redux/actions/table";
import { requestService } from "../request";
import type { ActiveBrandsPayloadItem } from "../../containers/ActiveBrands/types/ActiveBrands.types";

type Options = {
  page: number,
  limit: number,
  filters: Filters,
  sorting: Sorting,
  headers: string[],
}

export type Data = {
  "data": ActiveBrandsPayloadItem[],
  "count": number,
}

// eslint-disable-next-line import/prefer-default-export
export const getActiveBrands: (
  options: Options
) => PromiseResponse<mixed, Data> = (
  options
) => {
  const [[current, direction] = []] = Object.entries(options.sorting);

  return requestService
    .get(environment.endpoints.get.getActiveBrands, {
      params: { ...options },
      // $FlowFixMe paramsSerializer has mixed type in request service todo change type in request service
      paramsSerializer: (params: Options): string => qs.stringify({
        ...params,
        filters: Object.keys(params.filters).length ? params.filters : JSON.stringify({}),
        sorting: current ? {
          current,
          direction,
        } : JSON.stringify({}),
        headers: params.headers.length ? params.headers : JSON.stringify([]),
      }, { arrayFormat: "brackets", encode: true }),
    });
};
