// @flow
/* eslint-disable import/max-dependencies */
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch } from "redux";
import {
  changeTablePage,
  changeTableFilters,
  changeTableSorting, changeTablePageSize,
} from "@fas/cpa-state-manager/redux/actions/table";
import {
  getTableData,
  getTablePage,
  getTableTotalAmount,
  getTablePageSize,
  getTableFilters,
  getTableSorting,
} from "@fas/cpa-state-manager/services/selectors/table";
import {
  getLoadingState,
} from "@fas/cpa-state-manager/services/selectors/loading";
import { getDictionary } from "../../selectors/dictionaries";
import { BRANDS_TABLE } from "../../helpers/constants";
import { getActiveBrandsSaga } from "../../actions/activeBrands";
import { getDictionarySaga, type GetDictionarySaga } from "../../actions/dictionaries";
import ActiveBrands from "../../components/ActiveBrands";
import type { State, Actions } from "../../store";
import type {
  Props,
  OwnProps,
  StateToProps,
  DispatchToProps,
} from "./types/ActiveBrands.types";
import { getFirstAuthProductCompany } from "../../services/request";
import { PERFORMER } from "../../store";

const mapStateToProps: (state: State) => StateToProps = (state) => ({
  data: getTableData(state, BRANDS_TABLE),
  page: getTablePage(state, BRANDS_TABLE),
  total: getTableTotalAmount(state, BRANDS_TABLE),
  pageSize: getTablePageSize(state, BRANDS_TABLE),
  filters: getTableFilters(state, BRANDS_TABLE),
  sorting: getTableSorting(state, BRANDS_TABLE),
  verticalsDropdown: getDictionary(state, "vertical"),
  domainsDropdown: getDictionary(state, "brand"),
  nichesDropdown: getDictionary(state, "niche"),
  platformsDropdown: getDictionary(state, "platform"),
  // $FlowFixMe @fas/cpa-state-manager/services/selectors/loading fix required
  loading: getLoadingState(state, "getActiveBrandsListLoading"),
  // $FlowFixMe @fas/cpa-state-manager/services/selectors/loading fix required
  dropdownLoading: getLoadingState(state, "getDropdownListLoading"),
});

const mapDispatchToProps: (Dispatch<Actions>) => DispatchToProps = (dispatch) => bindActionCreators({
  onChangeTablePage: changeTablePage,
  onChangeTablePageSize: changeTablePageSize,
  onChangeTableFilters: changeTableFilters,
  onChangeTableSorting: changeTableSorting,
  onGetActiveBrandsData: getActiveBrandsSaga,
  onGetVerticalsDropdownData: (): GetDictionarySaga => getDictionarySaga("vertical"),
  onGetNichesDropdownData: (): GetDictionarySaga => getDictionarySaga("niche"),
  onGetPlatformsDropdownData: (): GetDictionarySaga => getDictionarySaga("platform"),
  onGetDomainsDropdownData: (): GetDictionarySaga => getDictionarySaga("brand", {
    performer: PERFORMER,
    filter: getFirstAuthProductCompany(),
  }),
}, dispatch);

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(ActiveBrands);
