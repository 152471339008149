// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Container, Box, Typography, type Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

type Classes = {
  root: string,
}

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: mixed} => ({
  root: {
    "& .MuiTypography-h2": {
      fontWeight: 700,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: "2rem",
      textTransform: "uppercase",
      background: "linear-gradient(to right, #1E88E5 0%, #EC497A 60%, #F05223 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      color: "transparent",
    },
    "& .MuiTypography-body1": {
      marginBottom: theme.spacing(3),
      color: "#4a4a4a",
    },
    "& .MuiTypography-h6": {
      fontWeight: 700,
      color: "#ec407a",
    },
    "& a": {
      color: "#1e88e5",
      textDecoration: "none",
    },
  },
}));

const Privacy: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();

  return (
    <Container>
      <Box className={classes.root}>
        <Typography component="h2" variant="h2" align="center">Privacy policy</Typography>
        <Typography component="p" variant="body1">
          Thank you for visiting the TOGETHERADS PTE. LTD (hereinafter ProfitSocial)
          Affiliate Network website located at
          &nbsp;
          <a href="//profitsocial.com">www.profitsocial.com</a>
          &nbsp;
          (the &ldquo;Site&ldquo;). ProfitSocial (&ldquo;we&ldquo;, &ldquo;us&ldquo;) is committed to protecting the
          privacy of your personally identifiable information. You must agree to this Privacy Policy, in its entirety,
          including our use of cookies, together with our Terms of Use, in order to access or otherwise use or view the
          Site. If you do not agree, you should not access or otherwise use the Site.
        </Typography>
        <Typography component="h6" variant="h6" align="left">Who we are</Typography>
        <Typography component="p" variant="body1">
          TOGETHERADS PTE. LTD is the data controller for the purposes of data protection legislation, which governs
          our approach to privacy.
        </Typography>
        <Typography component="h6" variant="h6" align="left">Your right</Typography>
        <Typography component="p" variant="body1">
          You may make a subject access request by contacting us through our support team. You can also contact us
          to update or amend any of your information to ensure it is accurate and up to date.
        </Typography>
        <Typography component="h6" variant="h6" align="left">Privacy Policies Used in Connection with Your Subscription</Typography>
        <Typography component="p" variant="body1">When you register for an Account (as defined in our Terms of Use) on our Site, we use a password-protected third party portal to store your personal information, and we may share your personal information with such third party (&ldquo;Third Party Agent&ldquo;) as is necessary for us to fulfil our obligations to you and for you to make use of the Site.</Typography>
        <Typography component="h6" variant="h6" align="left">Personally Identifiable Information</Typography>
        <Typography component="p" variant="body1">We collect personally identifiable information when you register for an Account or otherwise choose to provide personally identifiable information to us. Personally identifiable information is any information that can be used to identify or locate a particular person or entity. This may include, but is not limited to: business entity name and/or your title with the applicable business entity, as well as your personal and/or business entity related e-mail address, mailing address, daytime and/or cellular telephone numbers, fax number, account information (or other information that we require in order to pay any amounts due to you via your Account) and/or any other information requested on the applicable registration form. </Typography>
        <Typography component="h6" variant="h6" align="left">Non-Personally Identifiable Information</Typography>
        <Typography component="p" variant="body1">We may collect certain non-personally identifiable information about you when you visit certain pages of this Site and/or register for an Account, such as the type of browser you are using (e.g., Netscape, Internet Explorer), the type of operating system you are using, (e.g., Windows 8 or Mac OS) and the domain name of your Internet service provider (e.g., America Online, Earthlink), and share such information with our Third Party Agent. We use the non-personally identifiable information that we collect to improve the design and content of the Site and to enable us to personalize your Internet experience. We also may use this information in the aggregate to analyze Site usage.</Typography>
        <Typography component="h6" variant="h6" align="left">Cookies and Web Beacons</Typography>
        <Typography component="p" variant="body1">
          To enhance your experience with the Site, we use &ldquo;cookies.&ldquo; You cannot access the Site without
          agreeing to accept the cookies that we use with the Site. Cookies are small packets of data stored on your
          computer. Cookies are used by your computer&apos;s browser to store your preferences. Cookies, by themselves,
          do not tell us your e-mail address or other personally identifiable information. We use cookies to understand
          Site usage and to improve the content and offerings on the Site. You may set your browser to warn you that
          cookies are in use, or to block the use of cookies. Most browsers are set to accept cookies. If your browser
          is not set to accept cookies, certain uses of the Site may require you to go to your browser user preferences
          to enable cookies. Each browser is different, so check the &nbsp;Help&nbsp; menu of your browser to learn how
          to change your cookie preferences. If you change computers, operating systems or browsers, or use multiple
          computers or browsers, you will need to repeat this process for each computer and each browser. To find out
          more about cookies, please visit
          &nbsp;
          <a href="//www.aboutcookies.org">www.aboutcookies.org</a>
          .
        </Typography>
        <Typography component="p" variant="body1">We may additionally collect information using Web beacons, which are commonly referred to in the industry as web bugs, pixel tags or Clear GIFs. Web beacons are electronic images that may be used on the Site, in your Account, or in our emails to deliver cookies, count visits and determine if an email has been opened and acted upon.</Typography>
        <Typography component="h6" variant="h6" align="left">Use of Information</Typography>
        <Typography component="p" variant="body1">We use your information: (a) to send you information regarding your Account (b) to send you offers, newsletters and promotional material relating to us and carefully selected third parties ;(c) to monitor your compliance with the Terms and Conditions and this Privacy Policy; and/or (d) for validation, suppression, content improvement and feedback purposes. You agree that we, or our Third Party Agent, may contact you at any time with updates and/or any other information that we may deem appropriate for you to receive in connection with your Account on ProfitSocial.</Typography>
        <Typography component="h6" variant="h6" align="left">Information Sharing</Typography>
        <Typography component="p" variant="body1">As a general rule, and other than in connection with the limited exceptions set forth below, we will not sell, share or rent your personally identifiable information to or with others. Notwithstanding the foregoing, we may, from time to time, provide such information to certain third-party administrative vendors for efficiency purposes in providing administrative or program management services in connection with your Account. Any third-party vendor so used has agreed to protect the confidentiality of information provided by us. Further, we reserve the right to share your personally identifiable information: (a) where required by law, or requested by a court of competent jurisdiction; (b) in the event of any reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of our business, assets or stock; (c) where you are in violation of this Privacy Policy, and/or the Terms and Conditions; (d) in the case of a dispute; or (e) where we determine, in our sole discretion, that such disclosure is necessary to protect our rights and/or a third party, or necessary to protect us from liability of any kind. The aforementioned includes exchanging information with other companies and organizations for fraud protection.</Typography>
        <Typography component="h6" variant="h6" align="left">International Transfer of Data</Typography>
        <Typography component="p" variant="body1">We may transfer information that we collect about you and your end users, including personal information, to affiliated entities, or to other third parties across borders and from your country or jurisdiction to other countries or jurisdictions around the world. If you are located in the European Union or other regions with laws governing data collection and use that may differ from Singaporean law, please note that you are transferring information and permitting the transfer of information, including personal information, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction. You consent to the transfer of your information to other countries and the use and disclosure of information about you, including personal information, as described in this Privacy Policy.</Typography>
        <Typography component="p" variant="body1">Without derogating from the generality of any obligation, representation or warranty stipulated in Terms of Services and Privacy Policy, the Parties, are and will remain compliant at all times with applicable laws, regulations, seals, rules, policies and guidelines, including those of the platforms and channels through which a web traffic is generated (“Applicable Rules”). Applicable Rules include, but are not limited to, privacy protection laws of all the jurisdictions from which Data Subjects/End Users are originated.</Typography>
        <Typography component="h6" variant="h6" align="left">Data Retention</Typography>
        <Typography component="p" variant="body1">Subject to any mandatory obligations to store/maintain/delete data, your information may be retained by us and in our Third Party Agent portal, server logs, databases and records for so long as we deem necessary.</Typography>
        <Typography component="h6" variant="h6" align="left">Third Party Links</Typography>
        <Typography component="p" variant="body1">The Site may contain links to third-party websites (&ldquo;Third Party Websites&ldquo;). Please be aware that we are not responsible for the privacy practices of Third Party Websites. We encourage you to be aware when you leave the Site and to read the privacy policies of each and every website that collects personally identifiable information. This Privacy Policy applies solely to information collected by the Site.</Typography>
        <Typography component="h6" variant="h6" align="left">Your Employees</Typography>
        <Typography component="p" variant="body1">Your acceptance of the terms of this Privacy Policy, in their entirety, shall extend to your employees, if any. You agree that any of your employees that access your Account, or visit the Site under the scope of their employment with you agree to the terms of this Privacy Policy.</Typography>
        <Typography component="h6" variant="h6" align="left">Minors</Typography>
        <Typography component="p" variant="body1">We encourage parents and guardians to spend time online with their children and to participate and monitor the interactive activities of their children. We will never knowingly collect any personal information about individuals under eighteen (18) years of age. If we obtain actual knowledge that it we have collected personal information about an individual under eighteen (18) years of age, that information will be immediately deleted from its database.</Typography>
        <Typography component="h6" variant="h6" align="left">Security</Typography>
        <Typography component="p" variant="body1">
          We endeavor to safeguard and protect our Account holders&apos; information. When Account holders submit
          personally identifiable information to the Site , their personally identifiable information is protected both
          online and offline. When our registration process asks registrants to submit Sensitive Information (such as
          bank account information and/or credit card information), and when we transmit such Sensitive Information,
          that Sensitive Information is encrypted and protected with SSL encryption software. While we use SSL
          encryption to protect Sensitive Information online, we protect all other user information online and offline.
          The Third Party Agent servers that we utilize to store personally identifiable information in are kept in a
          secure physical environment. The Third Party Agent has security measures in place to protect the loss, misuse
          and alteration of personally identifiable information stored on its servers.
          <br />
          Please be advised that, although we take every reasonable precaution available to protect your data, no data
          transmission over the Internet can be guaranteed to be 100% secure. Therefore, we cannot warrant that your
          information will be absolutely secure. Any transmission of data at or through our Site is at your own risk.
          However, access to your personally identifiable information is strictly limited, and not accessible to the
          public. Only employees, and third party agents, that need the information to perform a specific job are
          granted access to personally identifiable information. Our employees are dedicated to ensuring the security
          and privacy of all user information. Employees not adhering to our written firm policies are subject to
          disciplinary action. In compliance with applicable federal and state laws, we shall notify you and any
          applicable regulatory agencies in the event that we learn of an information security breach with respect to
          your personally identifiable information. You will be notified via e-mail in the event of such a breach.
          Please be advised that notice may be delayed in order to address the needs of law enforcement, determine the
          scope of network damage, and to engage in remedial measures.
          <br />
          You acknowledge that you provide your personally identifiable information to us with knowledgeable consent
          and at your own risk.
        </Typography>
        <Typography component="h6" variant="h6" align="left">Deleting and Updating Your Information</Typography>
        <Typography component="p" variant="body1">
          If you would like to delete or update the personally identifiable information that we have collected from
          you, simply email us at:
          &nbsp;
          <a href="mailto:contact@profitsocial.com">contact@profitsocial.com</a>
          .
          <br />
          We will process your request, where possible and subject to the terms and conditions contained herein, within
          a reasonable period of time after receipt. To protect your privacy and security, we will take reasonable
          steps to help verify your identity before granting access or making corrections.
        </Typography>
        <Typography component="h6" variant="h6" align="left">Opting Out of Receiving E-mail</Typography>
        <Typography component="p" variant="body1">
          You may at any time choose to stop receiving promotional emails from Us by following the instructions at the
          end of each such email or by contacting us at
          &nbsp;
          <a href="mailto:contact@profitsocial.com">contact@profitsocial.com</a>
          &nbsp;
          . Should you be contacted by our Third Party Agent through email, you can follow the instructions at the end
          of each such email to stop receiving such emails. There may be a short delay of up to several business days
          while your request is being verified, deployed and processed across our servers. Notwithstanding the
          foregoing, we may continue to contact you for the purpose of communicating information relating to your
          Account, as well as to respond to any inquiry or request made by you.
        </Typography>
        <Typography component="h6" variant="h6" align="left">Notification of Changes</Typography>
        <Typography component="p" variant="body1">We reserve the right to change or update this Privacy Policy at any time by posting a clear and conspicuous notice on the Site explaining that we are changing our Privacy Policy. All Privacy Policy changes will take effect immediately upon their posting on the Site. Please check the Site periodically for any changes. Your continued use of the Site and/or acceptance of our e-mail communications following the posting of changes to this Privacy Policy will constitute your acceptance of any and all changes.</Typography>
        <Typography component="h6" variant="h6" align="left">Contact Us</Typography>
        <Typography component="p" variant="body1">
          If you have any questions regarding this Privacy Policy, or would like more information on our privacy
          practices, please contact us at:
          &nbsp;
          <a href="mailto:contact@profitsocial.com">contact@profitsocial.com</a>
          .
        </Typography>
      </Box>
    </Container>
  );
};

export default Privacy;
