// @flow
/* eslint-disable import/max-dependencies */
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import getDashboardDataSaga from "./sagas/getDashboardData";
import watchGetActiveBrandsListSaga from "./sagas/getActiveBrandsList";
import watchGetDropdownListSaga from "./sagas/getDropdownList";
import watchGetTransactionReportDataSaga from "./sagas/getTransactionReportData";
import watchDownloadTransactionReportDataSaga from "./sagas/downloadTransactionReport";
import watchSetAuthDataSaga from "./sagas/setAuthData";

export default function* mainSaga(): Saga<void> {
  return yield all([
    call(getDashboardDataSaga),
    call(watchGetActiveBrandsListSaga),
    call(watchGetDropdownListSaga),
    call(watchGetTransactionReportDataSaga),
    call(watchDownloadTransactionReportDataSaga),
    call(watchSetAuthDataSaga),
  ]);
}
