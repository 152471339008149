// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node } from "react";
import {
  Navigate, Routes, Route, useLocation, useNavigate,
} from "react-router-dom";
import {
  Box, Container, CssBaseline, Toolbar,
} from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import defaultTheme from "../../theme";
import Dashboard from "../../containers/Dashboard";
import TransactionReport from "../../containers/TransactionReport";
import ActiveBrands from "../../containers/ActiveBrands";
import PostbackUrls from "../PostbackUrls";
import Login from "../../containers/Login";
import PrivateRoute from "../PrivateRoute";
import Navigation from "../Navigation";
import Footer from "../Footer";
import Privacy from "../Privacy";
import Terms from "../Terms";

const useStyles = makeStyles(() => ({
  content: {
    flexGrow: 1,
    minWidth: "500px",
  },
  body: {
    maxWidth: "1600px",
  },
  toolbar: {
    minHeight: "57px",
  },
}));

function App(): Node {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Box display="flex">
          <Navigation />
          <main
            className={classes.content}
          >
            <Toolbar className={classes.toolbar} />
            <Box component={Container} py={3} className={classes.body}>
              <Routes>
                <Route path="/login" element={<Login location={location} history={navigate} />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
                <Route path="/transactionReport" element={<PrivateRoute component={TransactionReport} />} />
                <Route path="/activeBrands" element={<PrivateRoute component={ActiveBrands} />} />
                <Route path="/postbacks" element={<PrivateRoute component={PostbackUrls} />} />
                <Route path="*" element={<Navigate to="/dashboard" />} />
              </Routes>
            </Box>
            <Toolbar className={classes.toolbar} />
          </main>
          <Footer />
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
