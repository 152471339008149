// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";
import type { Params, Data } from "../dashboardApi";

export const downloadTransactionReport: (params: Params) => PromiseResponse<Params, string> = (params) => requestService
  .post(environment.endpoints.post.downloadTransactionReport, params);

export const getTransactionReportList: (params: Params) => PromiseResponse<Params, Data> = (params) => requestService
  .post(environment.endpoints.post.getDashboardData, params);
