// @flow
import React, {
} from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isAuthenticated } from "../../services/request";

type Props = {
  component: React$ComponentType<*>,
}

const PrivateRoute = ({ component: Component }: Props) => {
  const location = useLocation();
  return (isAuthenticated() ? <Component /> : <Navigate to="/login" state={{ from: location }} />);
};

export default PrivateRoute;
