// @flow
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import type { Dispatch } from "redux";

import type {
  Props,
  OwnProps,
  DispatchToProps,
} from "./types/Login.types";
import Login from "../../components/Login";
import { setAuthDataSaga } from "../../actions/auth";

const mapDispatchToProps: (Dispatch<*>) => DispatchToProps = (dispatch) => bindActionCreators({
  onLogin: setAuthDataSaga,
}, dispatch);

export default connect<Props, OwnProps, _, _, _, _>(null, mapDispatchToProps)(Login);
