// @flow
import {
  List,
  type List as ListType,
  type Map as MapType,
} from "immutable";
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { State as StoreWithDictionaryState } from "../../store";
import type { DropDownObjItemType, Dictionaries } from "../../reducers/dictionaries";

// eslint-disable-next-line import/prefer-default-export
export const getDictionary: OutputSelector<
  StoreWithDictionaryState,
  Dictionaries,
  DropDownObjItemType[]
> = createSelector(
  (state: StoreWithDictionaryState, dictionary: Dictionaries): ListType<MapType<string, string>> => state.dictionaries.getIn(["dropdownLists", dictionary], List()),
  (dropdownList: ListType<MapType<string, string>>): DropDownObjItemType[] => dropdownList
    .toArray()
    .map((item: MapType<string, string>): DropDownObjItemType => item.toObject())
);
