// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent, useEffect, useState,
} from "react";
import type { Dispatch } from "redux";
import { bindActionCreators } from "redux";
import {
  Box, Button, TextField, Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/lab";
import { KeyboardArrowRight } from "@mui/icons-material";
import { connect } from "react-redux";
import { getDictionarySaga } from "../../actions/dictionaries";
import type { GetDictionarySaga } from "../../actions/dictionaries";
import { getDictionary } from "../../selectors/dictionaries";
import type { Actions, State } from "../../store";
import type { DropDownObjItemType } from "../../reducers/dictionaries";
import { makeStylesTyped } from "../../helpers/generators";
import { PERFORMER } from "../../store";

export type OwnProps = $ReadOnly<{|
  onNext: (string[]) => mixed,
|}>;

export type StateToProps = $ReadOnly<{|
  productCompaniesDropdown: DropDownObjItemType[],
|}>;

export type DispatchToProps = $ReadOnly<{|
  onGetProductCompaniesDropdownData: () => mixed,
|}>;

export type Props = $ReadOnly<{|
  ...OwnProps,
  ...StateToProps,
  ...DispatchToProps,
|}>;

const mapStateToProps: (state: State) => StateToProps = (state) => ({
  productCompaniesDropdown: getDictionary(state, "productCompany"),
});

const mapDispatchToProps: (Dispatch<Actions>) => DispatchToProps = (dispatch) => bindActionCreators({
  onGetProductCompaniesDropdownData: (): GetDictionarySaga => getDictionarySaga("productCompany", { performer: PERFORMER }),
}, dispatch);

const useStyles = makeStylesTyped((theme) => ({
  input: {
    paddingBottom: theme.spacing(2),
  },
}));

const SelectProductCompanyField: StatelessFunctionalComponent<Props> = ({
  onNext,
  productCompaniesDropdown,
  onGetProductCompaniesDropdownData,
}: Props) => {
  const classes = useStyles();
  const [company, setCompany] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    onGetProductCompaniesDropdownData();
  }, []);

  useEffect(() => {
    const list = productCompaniesDropdown.map(({ label }) => ({ label, value: label }));
    setOptions([{ label: "All", value: list.map(({ label }) => label) }, ...list]);
  }, [productCompaniesDropdown]);

  const handleSubmit = () => {
    if (company) {
      onNext(Array.isArray(company.value) ? company.value : [company.value]);
    }
  };

  return (
    <>
      <Typography variant="body2">
        Choose Company
      </Typography>
      <Autocomplete
        id="combo-box"
        options={options}
        value={company}
        onChange={(e, value) => setCompany(value)}
        getOptionLabel={(option) => String(option.label)}
        getOptionSelected={(o, v) => o.value === v.value}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            margin="dense"
            required
            fullWidth
            size="small"
            placeholder="Choose from the list"
          />
        )}
        variant="outlined"
        margin="dense"
        required
        fullWidth
        size="small"
        name="company"
        className={classes.input}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        name="loginButton"
        onClick={handleSubmit}
        endIcon={<KeyboardArrowRight />}
        disabled={!company}
      >
        <Box width="100%">Log In</Box>
      </Button>
    </>
  );
};

export default connect<Props, OwnProps, _, _, _, _>(mapStateToProps, mapDispatchToProps)(SelectProductCompanyField);
