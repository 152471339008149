// @flow
import React, {
  type Node,
} from "react";
import {
  TableCell,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import type {
  Column,
  RowProps,
  RowClasses,
} from "./types/TableComponent.types";

const useStyles = makeStyles((theme) => ({
  cell: {
    color: theme.palette.text.title,
    textAlign: "center",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "18px",
  },
}));

function Row <T: Object>({
  columns,
  row,
  classes = {},
}: RowProps<T>): Node {
  const ownClasses: RowClasses = useStyles();

  return (
    <>
      <TableRow
        hover
        aria-checked={false}
        tabIndex={-1}
        data-testid="table-component-table-row"
        className={clsx(ownClasses.row, classes.row)}
      >
        {columns.map((column: Column<T>): Node => (
          <TableCell
            size="small"
            key={column.key}
            className={clsx(ownClasses.cell, classes.cell, column.className)}
            colSpan={column.colSpan || 1}
          >
            {column.render ? column.render({ row, column }) : String(row[column.field])}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
}

const useStylesTotal = makeStyles((theme) => ({
  cell: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
}));

export const TotalRow = <T: Object>(props: RowProps<T>) => <Row {...props} classes={useStylesTotal()} />;

export default Row;
