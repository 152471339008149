// @flow
import environment from "environment";
import type { PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { requestService } from "../request";

export type Fields = ("date"
  | "performer"
  | "customer"
  | "department"
  | "country"
  | "platform"
  | "age"
  | "invoiceId"
  | "invoiceNumber"
  | "gender")

export type Filters = {
  "dateFrom": string, // "2021-01-30",
  "dateTo": string, // "2021-01-30",
  "department": string[], // CPA

  "customer"?: string[],
  "performer"?: string[],
  "country"?: string[],
  "platform"?: string[],
  "age"?: string[],
  "invoiceId"?: number[],
  "invoiceNumber"?: string[],
  "gender"?: string[]
};

export type Params = {
  "filters": Filters,
  "fields": Fields[],
  "group": {
    "date": "day" | "month" | "year",
    "department": boolean,

    "performer"?: boolean,
    "customer"?: boolean,
    "country"?: boolean,
    "platform"?: boolean,
    "age"?: boolean,
    "invoiceId"?: boolean,
    "invoiceNumber"?: boolean,
    "gender"?: boolean
  }
}

export type ItemData = {
  date: string,
  customer?: string,
  performer?: string,
  department?: string,
  country?: string,
  platform?: string,
  age?: string,
  invoiceId?: number,
  invoiceNumber?:string,
  gender?: string,
  quantity?: number,
  cost?: number,
  amount?: number,
}

export type TotalData = {
  quantity: number,
  cost: number,
  amount: number,
};

export type Data = {
  "result": ItemData[],
  "total": {
    "total": number,
    "nextOffset": number,
    "result": TotalData,
  },
}

export const getDashboard = (params: Params): PromiseResponse<Params, Data> => requestService
  .post(environment.endpoints.post.getDashboardData, params);
