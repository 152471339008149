// @flow
import React, {
  useState,
  type StatelessFunctionalComponent,
  type Element,
} from "react";
import {
  ClickAwayListener,
  Tooltip,
} from "@mui/material";

export type ChildProps = {
  copy: () => void,
}

type UseState<T> = [T, (((T) => T) | T) => void];

export type Props = {
  children: (props: ChildProps) => Element<*>,
  copy: () => void,
}

const CopyToClipboard: StatelessFunctionalComponent<Props> = ({
  children,
  copy,
}: Props) => {
  const [opened, setOpened]: UseState<boolean> = useState(false);

  const handleTooltipOpen: () => void = () => {
    setOpened(true);
  };

  const handleTooltipClose: () => void = () => {
    setOpened(false);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={opened}
          leaveDelay={1500}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title="Copied"
          data-testid="copy-tooltip"
        >
          {children({
            copy: () => {
              copy();
              handleTooltipOpen();
            },
          })}
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export default CopyToClipboard;
