const prefix = "/api/v1/bank";

export default (baseUrl) => ({
  baseUrl,
  endpoints: {
    get: {
      getActiveBrands: `${baseUrl}${prefix}/activebrands`,
      getDropdownList: `${baseUrl}${prefix}/dropdown`,
    },
    post: {
      login: `${baseUrl}/api/auth/login`,
      getDashboardData: `${baseUrl}/api/v1/invoiceTransactions`,
      downloadTransactionReport: `${baseUrl}/api/v1/exportInvoiceTransactions`
    },
    delete: {},
    redirect: {},
  },
});
