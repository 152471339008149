// @flow
import React, {
  type StatelessFunctionalComponent, useState,
} from "react";
import { useLocation } from "react-router-dom";
import { isAuthenticated, logout } from "../../services/request";
import Header from "../Header";
import AppNavSidebar from "../AppNavSidebar";
import { redirect } from "../../helpers/generators";

type Props = {}

const Navigation: StatelessFunctionalComponent<Props> = () => {
  const [isExpand, setIsExpand] = useState<boolean>(true);
  const isShowAction: boolean = isAuthenticated();
  useLocation();

  return (
    <>
      <Header
        isShowAction={isShowAction}
        isExpand={isExpand}
        onToggleExpand={() => setIsExpand(!isExpand)}
        onLogout={() => {
          logout();
          redirect("/");
        }}
      />
      {isShowAction && (
        <AppNavSidebar
          isOpen={isExpand}
        />
      )}
    </>
  );
};

export default Navigation;
