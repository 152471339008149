// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";

export type ChildProps = {
  open: () => void
}

type Props = {
  banner: string,
  title: string,
  open: boolean,
  handleClose: () => void,
}

const BannerDialog: StatelessFunctionalComponent<Props> = ({
  banner,
  title,
  open,
  handleClose,
}: Props) => (
  <Dialog
    open={open}
    onClose={handleClose}
  >
    <DialogContent>
      <img
        width="100%"
        src={banner}
        alt="Banner url"
      />
      <Typography>{title}</Typography>
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        size="large"
        onClick={handleClose}
        fullWidth
        data-testid="banner-dialog-close-btn"
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default BannerDialog;
