// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Container, Box, List, ListItem, Typography, type Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

type Classes = {
  root: string,
}

const useStyles: () => Classes = makeStyles((theme: Theme): {[key: $Keys<Classes>]: mixed} => ({
  root: {
    "& .MuiTypography-h2": {
      fontWeight: 700,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      fontSize: "2rem",
      textTransform: "uppercase",
      background: "linear-gradient(to right, #1E88E5 0%, #EC497A 60%, #F05223 100%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      color: "transparent",
    },
    "& .MuiTypography-subtitle2": {
      marginBottom: theme.spacing(3),
      color: "#9a9a9a",
    },
    "& .MuiTypography-body1": {
      marginBottom: theme.spacing(3),
      color: "#4a4a4a",
    },
    "& .MuiTypography-h6": {
      fontWeight: 700,
      color: "#ec407a",
    },
    "& a": {
      color: "#1e88e5",
      textDecoration: "none",
    },
  },
}));

const Terms: StatelessFunctionalComponent<{}> = () => {
  const classes: Classes = useStyles();

  return (
    <Container>
      <Box className={classes.root}>
        <Typography component="h2" variant="h2" align="center">TERMS OF SERVICE</Typography>
        <Typography component="h6" variant="h6" align="left">DEFINITIONS</Typography>
        <Typography component="p" variant="body1">
          Affiliate Program – the program owned by a third party available to join as an affiliate via
          &nbsp;
          <a href="//profitsocial.com">www.profitsocial.com</a>
          &nbsp;
          TOGETHERADS PTE. LTD – the owner of the ProfitSocial affiliate program
          <br />
          ProfitSocial – our web based platform used for affiliates and advertisers
          <br />
          Partners – third party owners or authorized operators of Program Web Sites
          <br />
          Program Web Sites - websites of our Partners featured and made available to you through ProfitSocial and the
          Affiliate Programs associated therein
          <br />
          This Affiliate Program Operating Agreement (the &ldquo;Agreement&ldquo;) is made and entered into by and
          between (1) TOGETHERADS PTE. LTD Affiliate Network (&ldquo;we&ldquo; or &ldquo;Company&ldquo; or
          &ldquo;Us&ldquo;), operator of ProfitSocial and (2) you, (&ldquo;you&ldquo; or &ldquo;Affiliate&ldquo;) the
          person or party submitting an application to join ProfitSocial. The terms and conditions contained in this
          Agreement apply to your participation in the Affiliate Programs offered via ProfitSocial and your use of
          ProfitSocial. Each Affiliate Program is operated by a third party Partner, and links may be provided to
          particular websites of that Partner and their respective Affiliate Program on offer to you via ProfitSocial,
          together with additional terms and policies of such Partners which are expressly incorporated herein. By
          submitting an application to join ProfitSocial and/or accessing/using ProfitSocial, you expressly consent to
          all the terms and conditions of this Agreement.
        </Typography>
        <Typography component="h6" variant="h6" align="left">A. THE PROFITSOCIAL AFFILIATE PROGRAM PARTICIPATION</Typography>
        <Typography component="p" variant="body1">
          A1 Affiliate Application and Account Creation
          <br />
          (a) You must submit an application via ProfitSocial.com. You must accurately complete the application to
          become an affiliate (and provide us with future updates) and not use any aliases or other means to mask your
          true identity or contact information. You agree to update such information as and when required or requested,
          and you agree that your account is for your sole use and you shall not allow any other person or party to use
          your account, nor shall you make use of any other person or party`s account.
          <br />
          (b) If you are an individual and not a trading entity, in order to enroll and participate as an affiliate,
          you must be over eighteen (18) years of age and/or over the age of majority if residing and/or conducting
          business in states, provinces or countries where the age of majority is greater than eighteen (18) years of
          age.
          <br />
          (c) Your participation use of ProfitSocial is subject to our approval. We reserve the right to reject your
          application for any reason, including but not limited to the reason that we believe your traffic sources or
          promotional methods are unsuitable for the Affiliate Programs, or if we suspect you may breach this Agreement.
          <br />
          (d) Prohibited Territories: Due to excessive fraud, we do not allow the signup of affiliates from certain
          countries or regions so we reserve the right to deny any affiliate application from any country at our sole
          discretion. We also reserve the right to add or remove countries from time to time as business requirements
          may dictate.
          <br />
          (e) As described fully in this Agreement below, we reserve the right to suspend or terminate your access to
          ProfitSocial, or any Affiliate Program therein, at any time for any reason.
          <br />
          (f) If your application is accepted, we will provide you with login details for your Affiliate Account. Upon
          first logging in to your Affiliate Account, you will change its password and ensure that all data contained
          in your Account is accurate and up to date.
          <br />
          (g) Once approved, we will make available to you the relevant Affiliate Program graphic and textual links to
          the Program Web Sites and/or other creative materials (collectively, the &ldquo;Links&ldquo;) which you may
          display on web sites owned or controlled by you, in emails sent by you and clearly identified as coming from
          you and in online advertisements (collectively, &ldquo;Media&ldquo;). The Links will serve to identify you as
          a member of an Affiliate Program and will establish a link from your Media to the Program Web Site.
          <br />
          A2 YOUR WARRANTIES
          <br />
          In addition to any other warranties or representations made by you under this Agreement, by submitting an
          application, and by using your Affiliate Account you expressly warrant that:
        </Typography>
        <List>
          <ListItem>
            all information contained in your application and Affiliate Account is complete and accurate, and up to
            date;
          </ListItem>
          <ListItem>
            you are not based in any of the Prohibited Territories, nor do you have any personal or commercial
            connections to any person or party based in the Prohibited Territories;
          </ListItem>
          <ListItem>
            you have full capacity and authority to enter into this binding Agreement on behalf of yourself or any
            legal entity on which you claim to act on behalf of;
          </ListItem>
          <ListItem>
            if you are an affiliate network, that you have bound your affiliates to the same conditions and
            restrictions as you are bound to under this Agreement.
          </ListItem>
          <ListItem>
            you will keeps the login data for your Account at all times strictly confidential and will not share these
            details with any other party or person. You agree that you will remain fully liable for all activity
            conducted through your Account. You must promptly inform us of any theft of or unauthorized use of your
            login data.
          </ListItem>
        </List>
        <Typography component="h6" variant="h6" align="left">B. PAYMENTS AND COMMISSIONS</Typography>
        <Typography component="p" variant="body1">
          of this Agreement:
          <br />
          B1. We will pay Affiliate for each Qualified Action (the &ldquo;Commission&ldquo;). A &ldquo;Qualified Action
          &ldquo; means an individual person who (i) accesses the Program Web Site via the Link, where the Link is the
          last link to the Program Web Site, (ii) is not a computer generated user, such as a robot, spider, computer
          script or other automated, artificial or fraudulent method to appear like an individual, real live person,
          (iii) is not using pre-populated fields (iv) completes all of the information required for such action within
          the time period allowed by us and/or Partners and (v) is not later determined by us and/or Partners to be
          fraudulent, incomplete, unqualified or a duplicate (vi) does not later request a refund or initiate a
          chargeback or reverse a payment. In addition, we may reject an action which is not a lead or sale from the
          country of origin. We reserve the right to reject any action which is not a Qualified Action under this
          Agreement as amended from time to time. On Pay Per Lead program you must earn $200 per month. Otherwise we
          reserve the right to switch your account to Pay Per Sale program. For all Affiliate Programs where the
          payable action is a sale (i.e. Pay Per Sale) We reserve the right upon the Partner’s request to do the
          following: if an Affiliate is paid for a given Qualified Action but the respective sale is refunded
          afterwards the commission amount paid for this Action can be deducted from any future commission due to the
          Affiliate. Please note that if an end user subscribes using a prepaid card that cannot be rebilled you will
          receive the exact amount of their payment, not the default Pay Per Sale.
          <br />
          B2. We will pay you any Commissions on Net15 basis, provided that your account is currently greater than
          $250 (or $3000 if you have chosen to be paid by bank transfer) (each being &ldquo;Minimum Balance&ldquo;). We
          can consider weekly payments in case your commission is greater than $1000 per week (or $3000 if you have
          chosen to be paid by bank transfer) on a permanent basis subject to agreement with your account manager. If
          you have not reached the given limit ($1000) at least once, your payment plan will be downgraded and you will
          be paid on Net15 basis instead of a weekly one. In order to switch to weekly-basis payments again you should
          reach the given limit 5 (five) weeks in a row. Such consequence will upgrade you to weekly payments again. For
          payments exceeding $3000, liability for all bank transaction fees will be shared equally by you and us.
          Accounts with a balance of less than the Minimum Balance will roll over to the next month, and will continue
          to roll over monthly until the Minimum Balance is reached. We reserve the right to charge back to your account
          any previously paid Qualified Actions that are later determined to have not met the requirements to be a
          Qualified Action. You as an affiliate are an independent contractor and the relationship between the Company
          and you is not one of employment relationship. You are solely responsible for any taxes or social security
          costs due as a result of any payments received from the Company. You understand and agree that, if at any
          time we believe your account has been compromised, or this Agreement has been, or may have been breached, we
          reserve the right to withhold making any payment to you until such time as we have concluded our
          investigation and you agree to fully co-operate with any investigation at your own cost including providing
          all required identification documents and other documents if so requested. Your failure to promptly comply
          with any notification will result in your payments being delayed. We may withhold your final payment for a
          reasonable period of time to make sure that the total commission is calculated accurately and the correct
          amount is paid.
          <br />
          We reserve the right not to pay the commission earned by an affiliate (to reset the balance) and / or close
          the account at our own discretion in the following cases:
          <br />
          1) If an affiliate fails to provide the correct payment details in affiliate’s profile or if affiliate, by
          any reason, fails to claim the earned commission after expiration of one (1) year period from the moment of
          reaching the minimum amount to be paid ($ 100)
          <br />
          2) If within four (4) months from the moment of the first paid action (the entered lead) affiliate did not
          reach the minimum of $ 100. You can not provide the same payment information/data within several accounts or
          your account will be automatically blocked if you enter payment information which is similar to the
          information for another account.
          <br />
          Please never share your payment information/data with other users.
          <br />
          B3. Payment for Commissions is dependent upon Partners providing such funds to us, and therefore, you agree
          that we shall only be liable to you for Commissions to the extent that such funds from the Partners have been
          received. You hereby release us from any claim for
          Commissions if such funds from the Partners have not been received
          <br />
          B4. All Commissions will be paid in USD. All payments in another currency will be converted into USD in
          accordance with our exchange rate policies and procedures based upon http://www.xe.com/currencytables/
          rate of currency by the date of Qualified Action.
          <br />
          B5. We shall automatically generate an invoice on your behalf for all Commissions payable under this Agreement
          and shall remit payment to you based upon that invoice. All tracking of Links and determinations of Qualified
          Actions and Commissions shall be made by us in our sole discretion. In the event that you wish to dispute in
          good faith any portion of an invoice, you must submit that dispute to us in writing and in sufficient detail
          within thirty (30) days of the date on the invoice. If you do not dispute the invoice as set forth herein,
          then you agree that it irrevocably waives any claims based upon that invoice. All payments are made only on
          the basis of statistics in our tracking system. Any disputes on statistics will be considered by our team of
          affiliate managers.
          <br />
          B6. If you have an outstanding balance due to us under this Agreement or any other agreement between the you
          and us, whether or not related to the Affiliate Program or your Affiliate Account, you agree that we may
          offset any such amounts due to us from amounts payable to you under this Agreement.
          <br />
          B7. Referral Commissions are accrued on each payment transaction for each referred billable account as 5% of
          the transaction for the first six months on that particular account or until it is closed, whichever comes
          earlier. New accounts are considered referred by you if they sign up through your referral link. For payment
          terms - see point B2. For the payment your Referral Commission will be added to the regular Commissions and
          processed in one invoice if not agreed differently.
          <br />
          B8. Wrong, incorrect or out-of-date payment details for commission payments provided by you oblige us to pay
          bank fees/charges for return of the commission paid to the wrong payment account. If you provide us with
          wrong, incorrect and/or out-of-date payment details, We reserve the right to charge you with an additional
          sum (hereinafter - Fine) when effecting payment of commission to you after such violations. The Fines are
          applied as follows:
          <br />
          1) You provided us with wrong, incorrect and/or out-of-date payment details;
          <br />
          2) Your payment details were changed without timely warning Us/your personal manager;
          <br />
          3) Your payment details were changed untimely (you warned your manager after payment was sent etc.);
          <br />
          4) Other situations where we incur additional expenses due to your fault.
          <br />
          Invoices up to 500$ - Fine of $ 10 or equivalent in currency of payment
          <br />
          Invoices up to 500-1k – Fine of $ 20 or equivalent in currency of payment
          <br />
          Invoices from 1k to 5k - Fine of $ 30 or equivalent in currency of payment
          <br />
          Invoices above 5k - the amount of Fine is a subject to each individual case but not less than $ 150 or
          equivalent in currency.
        </Typography>
        <Typography component="h6" variant="h6" align="left">C. AFFILIATE OBLIGATIONS</Typography>
        <Typography component="p" variant="body1">
          C1 In addition to any other warranties or undertakings within this Agreement, you expressly accept,
          understand and agree to the following obligations, by warranting and representing in good faith to us that:
          <br />
          (a) You have sole responsibility for the development, operation, and maintenance of, and all content on or
          linked to, your Media. You warrant that all materials posted on your Media or otherwise used in connection
          with any Affiliate Program (i) are not illegal, (ii) do not infringe upon the intellectual property or
          personal rights of any third party and (iii) do not contain or link to any material which is harmful,
          threatening, defamatory, obscene, sexually explicit, harassing, promotes violence, promotes discrimination
          (whether based on sex, religion, race, ethnicity, nationality, disability or age), promotes illegal
          activities (such as gambling), contains profanity or otherwise contains materials that we or any Partner
          (acting through us or directly) informs you that it considers to be unacceptable, or damaging to the
          reputation of us, or any Partner acting in our sole discretion (collectively, &ldquo;Prohibited
          Content&ldquo;).
          <br />
          (b) You will not make any representations, warranties or other statements concerning us, ProfitSocial,
          Partners, Program Websites or any of respective products or services offered by us or Partners, except as
          expressly authorized herein.
          <br />
          (c) Your Media does not copy or resemble the look and feel of ProfitSocial or any Program Web Site or create
          the impression that your Media is endorsed by us or any Partner, without prior written permission.
          <br />
          (d) You will comply with all (i) obligations, requirements and restrictions under this Agreement (ii) laws,
          rules and regulations as they relate to you performance of your obligations hereunder, your business, your
          participation in ProfitSocial and Affiliate Programs, your Media or your use of the Links (iii) the terms,
          conditions, guidelines and policies of any third party services used by you in connection with the Affiliate
          Program, including but not limited to, email providers, social networking services and ad networks.
          <br />
          (e) You will never place Program Web Site ads or Media on any online auction platform (i.e. eBay, Amazon,
          etc).
          <br />
          C2 Data
          <br />
          You will always prominently post and make available to end-users, including prior to the collection of any
          personally identifiable information, a privacy policy in compliance with all applicable laws that clearly and
          thoroughly discloses all information collection, use and sharing practices, including providing for the
          collection of such personally identifiable information in connection with the Affiliate Program and sharing
          such personally identifiable information to us and Partners for purposes of us delivering our obligations to
          end users.
          <br />
          C3 The following additional program-specific terms shall apply to any promotional programs set forth below:
          <br />
          C3.1. Email Campaigns:
          <br />
          (a) Advertising through the use of e-mail or links within e-mail is strictly prohibited. TOGETHERADS PTE. LTD
          reserves the right to immediately suspend and/or terminate your Account without further notice or pay if it
          is discovered that you (or someone acting on your behalf, directly or indirectly) has violated this rule. If
          you would like to promote any of Program Websites by e-mail please send a request to
          &nbsp;
          <a href="mailto:contact@profitsocial.com">contact@profitsocial.com</a>
          . It is your responsibility to obtain a copy of Partners’Email policy guidelines (links to terms and policies
          of the Partners) before you start promoting any of their campaigns via email marketing. You may get a copy of
          our partners’ guidelines
          &nbsp;
          <a href="http://affiliates.togethernetworks.com/PPC_Guidelines.doc">here</a>
          . Any email marketing which is not previously approved by us, including spamming of any sort and violation of
          any of the applicable rules and regulations will result in an immediate ban from ProfitSocial and Program
          Websites without pay.
          <br />
          (b) For all approved email campaigns, you must download the &ldquo;Suppression List&ldquo; ProfitSocial. You
          undertake to filter your email list by removing any entries appearing on the Suppression List and will only
          send emails to the remaining addresses on your email list. We will provide an opt-out method in all Links,
          however, if any opt-out requests come directly to you, you shall immediately forward them to us at
          &nbsp;
          <a href="mailto:contact@profitsocial.com">contact@profitsocial.com</a>
          . Any of your emails containing the Links may not include any content other than the Links, except as
          required by applicable law.
          <br />
          (c) You understand and accept that failure to download the Suppression List and remove all emails from the
          database before mailing may result in Commission withholdings, removal or suspension from all or part of the
          Affiliate Program(s) and/or ProfitSocial, possible legal action and any other rights or remedies available to
          us or Partners pursuant to this Agreement or otherwise. You warrant that you will not mail or market to any
          suppression files generated through ProfitSocial , and that doing so may result in Commission withholdings,
          removal or suspension from the Affiliate Program(s) and/or ProfitSocial, possible legal action and any other
          rights or remedies available to us or Partners pursuant to this Agreement or otherwise.
          <br />
          C3.2 Advertising Campaigns
          <br />
          (a) No Links can appear to be associated with or be positioned on chat rooms or bulletin boards unless
          otherwise expressly agreed by Product owners through ProfitSocial. No chat traffic advertisements should be
          used by you during the campaigns. Any pop-ups/unders used for the Affiliate Program shall be clearly
          identified as Affiliate served in the title bar of the window and any client-side ad serving software used by
          You shall only have been installed on an end-user&apos;s computer if the function of the software is clearly
          disclosed to end-users prior to installation, the installation is pursuant to an affirmatively accepted and
          plain-English end user license agreement and the software be easily removed according to generally accepted
          methods.
          <br />
          (b) In the event that any campaign contains a Cap (meaning a maximum payout per campaign, or maximum number
          of clicks/leads/sales accepted per campaign ), you confirm and agree that any and all actions exceeding this
          Cap will not qualify for payment to you. You are solely responsible for monitoring any Cap.
          <br />
          C3.3 Affiliate Network Campaigns.
          <br />
          For all Affiliate&apos;s that maintain their own affiliate networks, You agree to place the Links in its
          affiliate network (the &ldquo;Network&ldquo;) for access and use by those affiliates in Affiliate&apos;s
          Network (each a &ldquo;Third Party Affiliate&ldquo;). You agree that it will expressly forbid any Third Party
          Affiliate to modify the Links in any way. You agree to maintain its Network according to the highest industry
          standards. You shall not permit any party to be a Third Party You whose web site or business model involves
          content containing Prohibited Content. All Third Party Affiliates must be in good standing with You. You must
          require and confirm that all Third Party Affiliates affirmatively accept, through verifiable means, this
          Agreement prior to obtaining access to the Links. You shall promptly terminate any Third Party Affiliate who
          takes, or could reasonably be expected to take, any action that violates the terms and conditions of this
          Agreement. In the event that either party suspects any wrongdoing by a Third Party Affiliate with respect to
          the Links, You shall promptly disclose to ProfitSocial the identity and contact information for such Third
          Party Affiliate. Affiliate shall promptly remove any Third Party Affiliate from the Affiliate Program and
          terminate their access to future offers operated by ProfitSocial in the Network upon written notice from
          ProfitSocial. Unless ProfitSocial has been provided with all truthful and complete contact information for a
          Third Party Affiliate and such Third Party Affiliate has affirmatively accepted this Agreement as recorded by
          ProfitSocial, You shall remain liable for all acts or omissions of any Third Party Affiliate.
          <br />
          C3.4 Social network usage.
          <br />
          You or any party acting on your behalf, directly or indirectly is strictly prohibited from using social
          networking sites including but not limited to Twitter, Facebook, Instagram and other social networks to
          promote any Program Web Sites. If it is suspected that any leads have been earned via such social channels,
          and a breach of this clause has been committed, You will not be paid for such leads.
          <br />
          C4 Mobile devices and traffic
          <br />
          (a) Desktop and tablet devices traffic is accepted only for web landing pages.
          <br />
          (b) Android and iOS devices (excluding tablets) traffic is accepted only for mobile landing pages.
          <br />
          (c) iPod touch, iPad and BlackBerry devices are prohibited for mobile landing pages. The list of unacceptable
          devices is updated on a regular basis and can be provided by an AM on your request. It is your responsibility
          to ensure that you check the latest guidelines and restrictions with your AM prior to launching a campaign.
          <br />
          C4.1 You or any party acting on your behalf, directly or indirectly is strictly prohibited from using mobile
          phone numbers to promote any Program Web Sites. If it is suspected that any leads have been earned via such
          social channels, and a breach of this clause has been committed, You will not be paid for such leads.
          <br />
          C5 Private Programs
          <br />
          (a) You must obtain approval from our affiliate managers prior to launching any private program.
          <br />
          (b) Active private campaigns should be paused within 48 hours (including weekends) upon AM request. The
          commissions for all leads received after that term will not be counted as due to be paid for.
          <br />
          C5.1 You must obtain approval from our affiliate managers prior to launching any private program.
          <br />
          C5.2 You must not re-launch any private program that has been paused for more than 5 calendar days, without
          affiliate manager&apos;s permission.
          <br />
          С5.3. Once you&apos;ve got the Links from your AM you need to start the campaign within a week. If you have
          not started the campaign, within one-week term, such campaign is considered not valid and you need to confirm
          the Links and re-request a permission to launch traffic again.
        </Typography>
        <Typography component="h6" variant="h6" align="left">D. CONFIDENTIALITY</Typography>
        <Typography component="p" variant="body1">Except as otherwise provided in this Agreement or with Our consent, you agree that all information, including, without limitation, the terms of this Agreement, business and financial information, customer and vendor lists, and pricing and sales information, concerning us or any of our affiliates provided by or on behalf of any of them shall remain strictly confidential and secret and shall not be utilized, directly or indirectly, by you for any purpose other than your participation in the Affiliate Program, except and solely to the extent that any such information is generally known or available to the public through a source other than you. You shall not use any information obtained from the Affiliate Program to develop, enhance or operate a service that competes with the Affiliate Program, or assist another party to do the same.</Typography>
        <Typography component="h6" variant="h6" align="left">E. LIMITED LICENSE &amp; INTELLECTUAL PROPERTY</Typography>
        <Typography component="p" variant="body1">
          E1 You are not allowed to modify the company or Program Web Sites&apos; logos in any shape or form. Should you
          wish to obtain a copy of a specific size/format, you can send your request by email.
          <br />
          E2 You are not allowed to register any domain names which are identical to or similar to our Partners’ brands
          and trademarks, both registered marks and unregistered marks.
          <br />
          E3 Yous are specifically prohibited from creating negative sites in order to drive traffic to Program Web
          Sites provided by ProfitSocial..
          <br />
          E4 Any use of our Partners’ trademarks, registered and unregistered is prohibited without their preliminary
          express permission.
          <br />
          E5 You are not allowed to copy or &ldquo;lift&ldquo; text or photos or any other content from other websites
          without such websites owners/operators written permission. If you would like to use some copy to promote such
          content and have found some useful information on such sites, we ask you to thoroughly re-phrase the texts.
          Failure to do so can result in rejection from the affiliate program.
          <br />
          E6 It is prohibited to use any text ads created by You yourself (or any third party directly or indirectly
          associated with You) unless the creatives have been approved by ProfitSocial affiliate team.
          <br />
          E7 It is prohibited to post any information that does not correspond to the authentic data about the services
          offered by Program Web Sites and may affect the Company&apos;s reputation or be a reason for customer
          complaints (Example: &apos;Limited time offer&apos;/&apos;offer Expires: today&apos;s_date&apos; / &apos;Free
          Chatroom&apos; / or any other paid features available for Premium members only)
          <br />
          E8 You are prohibited from using any adult, pornographic, sexually explicit or otherwise offensive content to
          promote android and iOS applications operated by ProfitSocial. Any trademarks of Google, Apple companies and
          their subsidiaries including but not limited to Google Play and iTunes are prohibited to be used on any adult,
          pornographic, sexually explicit or offensive creatives and promotion pages.
          <br />
          E9 It is prohibited to abuse any third party copyright or trademarks. If we become aware of any affiliate
          breaching this provision and are contacted by the third party whose rights have been infringed, we shall
          provide that third party with any and all assistance they require to pursue a claim against you.
          <br />
          E10 We grant you a nonexclusive, nontransferable, revocable right to use the Links and to access Program Web
          Sites through the Links solely in accordance with the terms of this Agreement, for the sole purpose of
          identifying your Media as a participant in the Affiliate Program and assisting in increasing sales through the
          Program Web Site. You may not alter, modify, manipulate or create derivative works of the Links or any
          graphics, creative, copy or other materials provided by ProfitSocial in any way. You are only entitled to use
          the Links to the extent that you are a member in good standing of the Affiliate Program. We may revoke your
          license anytime by giving you written notice. Except as expressly stated herein, nothing in this Agreement is
          intended to grant you any rights to any of Partners’ trademarks, service marks, copyrights, patents or trade
          secrets exclusively provided by ProfitSocial
          <br />
          E11 You agree that ProfitSocial may use any suggestion, comment or recommendation you choose to provide to
          ProfitSocial without compensation.
          <br />
          E12 All rights not expressly granted in this Agreement are reserved by ProfitSocial.
        </Typography>
        <Typography component="h6" variant="h6" align="left">F. TERMINATION</Typography>
        <Typography component="p" variant="body1">
          F1 This Agreement shall commence on the date of our approval of your Affiliate Program application and shall
          continue thereafter until terminated as provided herein.
          <br />
          F2 You may terminate your participation in the Affiliate Program at any time by removing all Links from your
          Media, deleting all copies of the Links.
          <br />
          F3 We may terminate your participation in one or more offers provided by ProfitSocial or this Agreement at any
          time and for any reason which we deem appropriate with or without prior notice to you by disabling the Links
          or providing you with a written notice.
          <br />
          F4 We reserve the right to terminate your account if it is idle for two months or more.
          <br />
          F5 Consequences of termination:
          <br />
          (a) Upon termination of your participation in one or more Offers provided by ProfitSocial or this Agreement
          for any reason, you will immediately cease all use of and delete all Links, plus all ProfitSocial intellectual
          property, and will cease representing yourself as a ProfitSocial Affiliate Network for such one or more
          offers.
          <br />
          (b) All rights to validly accrued payments, causes of action and any provisions, which by their terms are
          intended to survive termination, shall survive any termination. However, if this Agreement is terminated by us
          because you have breached, threatened to breach, or we believe you are intending to breach this Agreement, you
          are not eligible to receive any Commissions, even if Commission had otherwise been properly due to you as at
          the date of termination.
        </Typography>
        <Typography component="h6" variant="h6" align="left">G. AFFILIATE REJECTIONS</Typography>
        <Typography component="p" variant="body1">
          We fully reserve all of our rights to suspend your account or terminate your account as provided herein for
          any reason, including but not limited to:
          <br />
          a)Our terms and conditions have been breached
          <br />
          b) Our Partners’ PPC Guidelines and Email Policy have not been properly followed
          <br />
          c) Your site has been using our partners’ brand name to promote a dating site that is not part of our
          portfolio
          <br />
          d)Your site is a free-hosted site
          <br />
          e) Your site only contains banners and very little content
          <br />
          f) Your site is still under construction
          <br />
          g) Your site is not available or is returning an error
          <br />
          h)Your site contains unacceptable or offensive material
          <br />
          i) You are operating an automatic re-direct from your site to one of our Partners’ domains
          <br />
          j) Your website(s) takes payments from customers for the provision of services or goods which are illegal in
          customer`s territory including but not limited to prostitution, weapons etc.
          <br />
          k) Your website(s) contains illegal photographic material or content.
        </Typography>
        <Typography component="h6" variant="h6" align="left">H. REMEDIES</Typography>
        <Typography component="p" variant="body1">
          H1 In addition to any other rights and remedies available to us under this Agreement ProfitSocial reserves the
          right to delete any actions submitted through your Links and withhold and freeze any unpaid Commissions or
          charge back paid Commissions to your account if (i) ProfitSocial determines that you have violated this
          Agreement, (ii) ProfitSocial receives any complaints about your participation in the Affiliate Program which
          ProfitSocial reasonably believes to violate this Agreement or (iii) any Qualified Action is later determined
          to have not met the requirements set forth in this Agreement or on the Affiliate Program.
          <br />
          H2 Such withholding or freezing of Commissions, or charge backs for paid Commissions, shall be without regard
          as to whether or not such Commissions were earned as a result of such breach. In the event of a material
          breach of this Agreement, ProfitSocial reserves the right to disclose your identity and contact information to
          appropriate law enforcement or regulatory authorities or any third party that has been directly damaged by
          your actions.
        </Typography>
        <Typography component="h6" variant="h6" align="left">J. ANTI-SPAM POLICY</Typography>
        <Typography component="p" variant="body1">
          J1 You must strictly comply with the federal CAN-SPAM Act of 2003 and Data Protection Act 1998 (the
          &ldquo;Act &ldquo;). All emails sent in connection with the Affiliate Program must include the appropriate
          party&apos;s opt-out link. From time to time, we may request - prior to your sending emails containing linking
          or referencing the Affiliate Program that you submit the final version of your email to ProfitSocial for
          approval by sending it to your ProfitSocial representative and upon receiving written approval from
          ProfitSocial of your email the email may be transmitted to third parties.
          <br />
          J2 It is solely your obligation to ensure that the email complies with the Act. You agree not to rely upon
          ProfitSocial&apos;s approval of your email for compliance with the Act, or assert any claim that you are in
          compliance with the Act based upon ProfitSocial&apos;s approval.
          <br />
          J3 You are not allowed to use any of the following (but not limited to): instant messaging spam, newsgroup
          spam, posting of comments with your affiliate URL embedded on sites you do not own, Web search engine spam,
          spam in blogs, wiki spam, Online classified ads spam, mobile phone messaging spam, Internet forum spam, chain
          letters, bulk e-mailings of any kind, junk e-mailing/junk fax transmissions, and file sharing network spam,
          and social networking sites.
        </Typography>
        <Typography component="h6" variant="h6" align="left">K. FRAUD AND SCAMS</Typography>
        <Typography component="p" variant="body1">
          You are expressly prohibited from using any persons, means, devices or arrangements to commit fraud, violate
          any applicable law, interfere with other affiliates or falsify information in connection with referrals
          through the Links or the generation of Commissions or exceed your permitted access to the Affiliate Program.
          Such acts include, but are in no way limited to, using automated means to increase the number of clicks
          through the Links or completion of any required information, using spyware, using stealware, cookie-stuffing
          and other deceptive acts or click-fraud. Company expressly prohibits using any hosting proxy/VPN or any other
          unacceptable tools and techniques by you or a third party related to you directly or indirectly and reserves
          the right to immediately decline and not to pay for all and any leads generated via hosting proxy/VPN or any
          other unacceptable tools and techniques used by you or third parties related to you directly or indirectly.
          ProfitSocial shall make all determinations about fraudulent activity in its sole discretion. Affiliate will
          not fraudulently add leads or clicks or inflate leads or clicks by fraudulent\scams traffic generation.
          Company has a right to decline and not to pay for leads generated via hosting proxy / VPN and other
          unacceptable tools and techniques, detected by ProfitSocial Internal Antifraud System. Any fraud, attempted or
          actual, shall be immediate grounds for us to terminate your account and withhold any and all sums outstanding
          to you.
          <br />
          You also agree that any of the following by you or any third party directly or indirectly controlled by you
          will cause all of your accounts to terminated without pay including but not limited to all of commissions owed
          to you by the Company, and will further result in you being precluded from ever participating in any of our
          Programs:
        </Typography>
        <List>
          <ListItem>
            Dissemination of unsolicited bulk E-mail, Instant Messages, Chatroom, Newsgroup, ICQ or IRC postings or any
            other forms of SPAM;
          </ListItem>
          <ListItem>
            Promoting or dealing in content such as but not limited to bestiality, rape, child pornography, violence, or
            any other illegal activity. The above also includes use of prohibited content in hidden meta tags, text,
            links, graphic(s) or any HTML;
          </ListItem>
          <ListItem>
            Misleading, cheating or attempting to mislead/cheat/defraud Company in any way;
          </ListItem>
          <ListItem>
            Using a misleading domain name on the Internet that re-directs traffic and may either deceive a person into
            viewing material that is obscene in nature or deceive a minor into viewing material that is harmful to
            minors on the Internet;
          </ListItem>
          <ListItem>
            Providing incorrect or incomplete account information and hijacking traffic;
          </ListItem>
          <ListItem>
            Requiring hits or signups for entering or obtaining access to goods or services on your (or third person’s)
            web site;
          </ListItem>
          <ListItem>
            Engaging any sort of mechanism or effort that falsely generates hits or signups, including signups by the
            Affiliate. If you wish to perform a test signup you must first E-mail us via the e-mail address provided in
            your account documentation;
          </ListItem>
          <ListItem>
            Violating or infringing any rights of any person or entity, including without limitation, any copyrights,
            trademark rights, patent rights, rights of publicity, privacy rights or any other intellectual, personal or
            property right violation or infringement;
          </ListItem>
          <ListItem>
            Cheating or defrauding any other affiliate program or sponsor;
          </ListItem>
          <ListItem>
            Hosting on non-adult free hosts that do not permit adult content. This includes (but is not limited to)
            Geocities, Angelire, Tripod, Xoom, CJB.net, or Hypermart;
          </ListItem>
          <ListItem>
            Misrepresenting Program Web site(s) provided by ProfitSocial in any manner, including but not limited to,
            misrepresenting the cost of membership, terms of membership, or content contained within Program web
            site(s);
          </ListItem>
          <ListItem>
            Misusing ProfitSocial and Program Web Sites site Terms and Conditions, including but not limited to,
            instructing the member to signup and cancel is also not permitted in any way, shape or form;
          </ListItem>
          <ListItem>
            Using any form of deceptive and/or unfair advertising;
          </ListItem>
          <ListItem>
            Bidding or purchasing online advertising tools/schemes incorporating or confused due to similarity any of
            Partners&apos; TM, service marks, or URLs. Advertising schemes which are prohibited for your use in
            connection with these Terms include pay-per-click models, sponsored links, search engine keywords, AdWords,
            or similar advertising schemes.
          </ListItem>
          <ListItem>
            Using SMS/text messages to deliver Ads to Users;
          </ListItem>
          <ListItem>
            Using names, photos or any other celebrities related information including but not limited to false 3rd
            party endorsements by celebrities or false rumors about any person/celebrity;
          </ListItem>
        </List>
        <Typography component="p" variant="body1">The following resources (and similar to those but not listed here) are prohibited for traffic: teasernet.com, popunder.net, clickunder.ru. It is also important to consider that the use of motivated (incentive) traffic resources is prohibited.</Typography>
        <Typography component="h6" variant="h6" align="left">L. REPRESENTATIONS AND WARRANTIES</Typography>
        <Typography component="p" variant="body1">You hereby represent and warrant that this Agreement constitutes your legal, valid, and binding obligation, enforceable against you in accordance with its terms and that you have the authority to enter into this Agreement. Subject to the other terms and conditions of this Agreement, ProfitSocial represents and warrants that it shall not knowingly violate any law, rule or regulation which is applicable to ProfitSocial&apos;s own business operations or Products provided by ProfitSocial.</Typography>
        <Typography component="h6" variant="h6" align="left">M. MODIFICATIONS</Typography>
        <Typography component="p" variant="body1">
          M1 In addition to any notice permitted to be given under this Agreement, we may modify any of the terms and
          conditions of this Agreement at any time. We will inform you about such changes by posting a notice on the
          Website or by sending you email with the proposal to get acquainted with the modified Agreement.
          <br />
          Such modified Agreement will become effective and will apply to you upon our posting such Agreement to the
          Website. Your continued use of our Service after such a modification has been posted shall be deemed to
          constitute acceptance by you of any such modified Agreement. It is your obligation to review the Agreement
          and to become aware of any modifications
          <br />
          Modifications may include, for example, changes in payment procedures, and your restrictions on account usage
          If the modifications are unacceptable to you, you may terminate this Agreement without penalty solely on the
          account of such termination within such ten (10) business day period.
          <br />
          M2 Your continued participation in this Affiliate Program after a change notice has been posted on the Website
          will constitute your acceptance of such change. In addition, ProfitSocial may change, suspend or discontinue
          any aspect of an offer or Link or remove, alter, or modify any tags, text, graphic or banner ad in connection
          with a Link. Affiliate agrees to promptly implement any request from ProfitSocial to remove, alter or modify
          any Link, graphic or banner ad that is being used by Affiliate as part of the Affiliate Program.
        </Typography>
        <Typography component="h6" variant="h6" align="left">N. INDEPENDENT INVESTIGATION</Typography>
        <Typography component="p" variant="body1">You acknowledge that you have read this Agreement and agree to all its terms and conditions. You have independently evaluated the desirability of participating in the Affiliate Program and each offer operated by ProfitSocial and are not relying on any representation, guarantee or statement other than as set forth in this Agreement or on the Affiliate Program.</Typography>
        <Typography component="h6" variant="h6" align="left">O. INDEMNIFICATION</Typography>
        <Typography component="p" variant="body1">O1 Affiliate hereby agrees to indemnify, defend and hold harmless ProfitSocial and Program Web Sites owners/operators and their respective subsidiaries, affiliates, partners and licensors, directors, officers, employees, owners and agents against any and all claims, actions, demands, liabilities, losses, damages, judgments, settlements, costs, and expenses (including reasonable attorneys&apos; fees and costs) based on (i) any failure or breach of this Agreement, including any representation, warranty, covenant, restriction or obligation made by Affiliate herein, (ii) any misuse by Affiliate, or by a party under the reasonable control of Affiliate or obtaining access through Affiliate, or the Links, or ProfitSocial or Partners’ intellectual property, or (iii) any claim related to your Media, including but not limited to, the content contained on such Media (except for the Links). </Typography>
        <Typography component="h6" variant="h6" align="left">P. DISCLAIMERS</Typography>
        <Typography component="p" variant="body1">PROFITSOCIAL SERVICE, EACH AFFILIATE PROGRAM AND LINKS, AND THE PRODUCTS AND SERVICES PROVIDED IN CONNECTION THEREWITH, ARE PROVIDED TO AFFILIATE &ldquo;AS IS&ldquo;. EXCEPT AS EXPRESSLY SET FORTH HEREIN, PROFITSOCIAL EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. PROFITSOCIAL DOES NOT WARRANT THAT THE AFFILIATE PROGRAM OR LINKS WILL MEET AFFILIATE&apos;S SPECIFIC REQUIREMENTS OR THAT THE OPERATION OF THE AFFILIATE PROGRAM OR LINKS WILL BE COMPLETELY ERROR- FREE OR UNINTERRUPTED. PROFITSOCIAL EXPRESSLY DISCLAIMS ANY LIABILITY FOR ANY ACT OR OMISSION OF A CLIENT OR THEIR PRODUCTS OR SERVICES. PROFITSOCIAL DOES NOT GUARANTEE THAT AFFILIATE WILL EARN ANY SPECIFIC AMOUNT OF COMMISSIONS.</Typography>
        <Typography component="h6" variant="h6" align="left">Q. LIMITATION OF LIABILITY</Typography>
        <Typography component="p" variant="body1">IN NO EVENT SHALL WE BE LIABLE FOR ANY UNAVAILABILITY OR INOPERABILITY OF THE LINKS, PROGRAM WEB SITES, TECHNICAL MALFUNCTION, COMPUTER ERROR, CORRUPTION OR LOSS OF INFORMATION, OR OTHER INJURY, DAMAGE OR DISRUPTION OF ANY KIND BEYOND OUR REASONABLE DIRECT CONTROL. IN NO EVENT WILL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PERSONAL INJURY / WRONGFUL DEATH, SPECIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, LOSS OF PROFITS OR LOSS OF BUSINESS OPPORTUNITY, EVEN IF SUCH DAMAGES ARE FORESEEABLE AND WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY THEREOF. OUR CUMULATIVE LIABILITY TO YOU, FROM ALL CAUSES OF ACTION AND ALL THEORIES OF LIABILITY, WILL BE LIMITED TO AND WILL NOT EXCEED THE AMOUNTS PAID BY US TO YOU IN COMMISSIONS DURING THE SIX (6) MONTHS IMMEDIATELY PRIOR TO SUCH CLAIM.</Typography>
        <Typography component="h6" variant="h6" align="left">R. GOVERNING LAW &amp; MISCELLANEOUS</Typography>
        <Typography component="p" variant="body1">
          R1 You shall be responsible for the payment of all attorneys fees and expenses incurred by ProfitSocial
          Affiliate Network to enforce the terms of this Agreement.
          <br />
          R2 This Agreement contains the entire agreement between ProfitSocial and you with respect to the subject
          matter hereof, and supersedes all prior and/or contemporaneous agreements or understandings, written or oral.
          You agree that ProfitSocial shall not be subject to or bound by any Affiliate insertion order or online terms
          and conditions that amend, conflict with or supplement this Agreement, regardless of whether ProfitSocial
          &ldquo;clicks through&ldquo; or otherwise indicates its acceptance thereof.
          <br />
          R3 You may not assign all or any part of this Agreement without ProfitSocial Affiliate Network&apos;s prior
          written consent. ProfitSocial may assign this Agreement at any time with notice to You. This Agreement will be
          binding on and will inure to the benefit of the legal representatives, successors and valid assigns of the
          parties hereto.
          <br />
          R4 The provisions of D, E, F, J, K, L and N to S inclusive shall survive the termination of this Agreement. If
          any provision of this Agreement is held to be void, invalid or inoperative, the remaining provisions of this
          Agreement shall continue in effect and the invalid portion of any provision shall be deemed modified to the
          least degree necessary to remedy such invalidity while retaining the original intent of the parties. Each
          party to this Agreement is an independent contractor in relation to the other party with respect to all
          matters arising under this Agreement.
          <br />
          R5 Nothing herein shall be deemed to establish a partnership, joint venture, association or employment
          relationship between the parties. No course of dealing nor any delay in exercising any rights hereunder shall
          operate as a waiver of any such rights. No waiver of any default or breach shall be deemed a continuing waiver
          or a waiver of any other breach or default. By submitting and application to Affiliate Program, you affirm and
          acknowledge that you have read this Agreement in its entirety and agree to be bound by all of its terms and
          conditions.
          <br />
          R6 If you do not wish to be bound by this Agreement, you should not submit an application to Affiliate
          Program. If an individual is accessing this Agreement on behalf of a business entity, by doing so, such
          individual represents that they have the legal capacity and authority to bind such business entity to this
          Agreement.
          <br />
          R7 Any dispute or claim arising out of or in connection with it or its subject matter or formation (including
          non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of England.
          The London Court of International Arbitration, shall have exclusive jurisdiction to hear and determine any
          claims, disputes, actions, or suits, which may arise under or out of this agreement. The Parties agree and
          voluntarily consent to the personal jurisdiction and venue of such courts for such purposes. If you have any
          questions or wish us to clarify any of these points, please send us an email
          &nbsp;
          <a href="mailto:contact@profitsocial.com">contact@profitsocial.com</a>
        </Typography>
      </Box>
    </Container>
  );
};

export default Terms;
