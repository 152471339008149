// @flow
import {
  Record, type List as ListType, List, type RecordFactory, type RecordOf,
} from "immutable";
import { SET_TRANSACTION_REPORT_FIELDS, SET_TRANSACTION_REPORT_IS_ADVANCED } from "../../helpers/constants";
import type { SetTransactionReportIsAdvanced, SetTransactionReportFields, Actions } from "../../actions/transactionReport";

export type DefaultStateType = {
  isAdvancedFilter: boolean,
  fields: ListType<string>,
}

const defaultState: DefaultStateType = {
  isAdvancedFilter: false,
  fields: List([
    "date",
    "department",
    "country",
    "platform",
    "age",
    "quantity",
    "cost",
    "amount",
    "invoiceId",
  ]),
};

export const makeState: RecordFactory<DefaultStateType> = Record(defaultState);

export type State = RecordOf<DefaultStateType>;

export const initialState: State = makeState();

export function initState(): State {
  return initialState;
}

export default (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case SET_TRANSACTION_REPORT_IS_ADVANCED: {
      const { isAdvancedFilter }: SetTransactionReportIsAdvanced = action;
      return state.set("isAdvancedFilter", isAdvancedFilter);
    }
    case SET_TRANSACTION_REPORT_FIELDS: {
      const { fields: dynamicFields }: SetTransactionReportFields = action;
      return state.set("fields", initialState.get("fields").merge(dynamicFields));
    }
    default:
      return state;
  }
};
