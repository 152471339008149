// @flow
/* eslint-disable import/max-dependencies */
import {
  call, put, select, takeEvery, delay,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import isEqual from "lodash.isequal";
import { setTableData, setTableTotalData, changeTableItemsTotalAmount } from "@fas/cpa-state-manager/redux/actions";
import { getTableFilters, getTablePage, getTablePageSize } from "@fas/cpa-state-manager/services/selectors/table";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading/actions";
import { changeTablePage } from "@fas/cpa-state-manager/redux/actions/table";
import {
  formatDateByGroup,
  prepareFields, prepareFilters,
} from "../../helpers/generators";
import { GET_TRANSACTION_REPORT_SAGA, TRANSACTION_TABLE } from "../../helpers/constants";
import { getTransactionReportFields } from "../../selectors/transactionReport";
import { setTransactionReportTableFields } from "../../actions/transactionReport";
import { getTransactionReportList } from "../../services/transactionReportApi";
import type { Fields } from "../../services/dashboardApi";

let oldFilters;

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("getTransactionReportLoading", true));
    const { dynamicFields, currency, ...filters } = yield select(getTableFilters, TRANSACTION_TABLE);

    if (!isEqual(filters, oldFilters)) {
      yield put(changeTablePage(TRANSACTION_TABLE, 1));
    }
    oldFilters = filters;

    if (currency !== "€") { // if we want to receive a different currency than euro, we simulate a call(timeout) and set fake data. Business requirements.
      yield delay(1000);
      yield put(setTableData(TRANSACTION_TABLE, []));
      yield put(changeTableItemsTotalAmount(TRANSACTION_TABLE, 0));
      yield put(setTableTotalData(TRANSACTION_TABLE, {}));
      return;
    }

    const limit: number = yield select(getTablePageSize, TRANSACTION_TABLE);
    const page: number = yield select(getTablePage, TRANSACTION_TABLE);
    yield put(setTransactionReportTableFields(dynamicFields));
    const fields: Fields[] = yield select(getTransactionReportFields);
    const group = {
      date: "day",
      department: true,
      performer: true,
      country: true,
      platform: true,
      age: true,
      customer: true,
      invoiceId: true,
    };

    const response = yield call(getTransactionReportList, {
      filters: prepareFilters(filters),
      fields: prepareFields(fields),
      group,
      limit,
      offset: (page - 1) * limit,
    });
    const data = response.data.result.map((item) => ({ ...item, date: formatDateByGroup(item.date, group.date) }));
    yield put(setTableData(TRANSACTION_TABLE, data));
    yield put(changeTableItemsTotalAmount(TRANSACTION_TABLE, response.data.total.total));
    yield put(setTableTotalData(TRANSACTION_TABLE, response.data.total.result));
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("getTransactionReportLoading", false));
  }
}

export default function* watchGetTransactionReportDataSaga(): Saga<void> {
  yield takeEvery(GET_TRANSACTION_REPORT_SAGA, (makeFetch: Function));
}
