// @flow
/* eslint-disable import/max-dependencies */
import {
  call, delay, put, select, takeEvery,
} from "redux-saga/effects";
import type { Saga } from "redux-saga";
import { getTableFilters } from "@fas/cpa-state-manager/services/selectors/table";
import setLoading from "@fas/cpa-state-manager/redux/actions/loading/actions";
import {
  downloadCsv, prepareFields, prepareFilters,
} from "../../helpers/generators";
import {
  DOWNLOAD_TRANSACTION_REPORT_SAGA,
  TRANSACTION_TABLE,
} from "../../helpers/constants";
import { getTransactionReportFields } from "../../selectors/transactionReport";
import { setTransactionReportTableFields } from "../../actions/transactionReport";
import { downloadTransactionReport } from "../../services/transactionReportApi";
import type { Fields } from "../../services/dashboardApi";

export function* makeFetch(): Saga<void> {
  try {
    yield put(setLoading("downloadTransactionReportLoading", true));
    const { dynamicFields, ...filters } = yield select(getTableFilters, TRANSACTION_TABLE);

    if (filters.currency !== "€") { // if we want to receive a different currency than euro, we simulate a call(timeout) and set fake data. Business requirements.
      yield delay(1000);
      yield call(downloadCsv, "");
      return;
    }

    const oldFields: string[] = yield select(getTransactionReportFields);
    yield put(setTransactionReportTableFields(dynamicFields));
    const fields: Fields[] = yield select(getTransactionReportFields);
    yield put(setTransactionReportTableFields(oldFields));

    const group = {
      date: "day",
      department: true,
      performer: true,
      country: true,
      platform: true,
      age: true,
      customer: true,
    };

    const response: { data: string } = yield call(downloadTransactionReport, {
      filters: prepareFilters(filters),
      fields: prepareFields(fields),
      group,
    });

    yield call(downloadCsv, response.data);
  }
  catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading("downloadTransactionReportLoading", false));
  }
}

export default function* watchDownloadTransactionReportDataSaga(): Saga<void> {
  yield takeEvery(DOWNLOAD_TRANSACTION_REPORT_SAGA, (makeFetch: Function));
}
