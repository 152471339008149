// @flow

const themeDefaultObject: mixed = {
  theme: "default",
  props: {},
  typography: {
    body1: {
      fontSize: "0.875rem",
    },
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    background: {
      default: "#fcfcfc",
    },
    primary: {
      light: "#e9f3fc",
      main: "#1E88E5",
      dark: "#005cb2",
    },
  },
  buttons: {
    main: {},
    secondary: {},
  },
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: "1rem",
        lineHeight: "1.1876em",
      },
    },
    "MuiTableCell": {
      root: {
        border: "1px solid rgba(224, 224, 224, 1)",
      },
    },
    MuiOutlinedInput: {
      input: {
        fontSize: "1rem",
        height: "1.1876em",
      },
      inputRoot: {
        lineHeight: "1.1876em",
      },
      inputMarginDense: {
        paddingTop: "8.5px",
        paddingBottom: "8.5px",
      },
    },
    MuiAutocomplete: {
      paper: {
        fontSize: "1rem",
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: "rgba(0, 0, 0, 0.3)",
        "&$checked": {
          color: "#1E88E5",
        },
      },
    },
    MuiTypography: {
      body1: {
        fontSize: "1rem",
      },
    },
    MuiButton: {
      containedPrimary: {
        minWidth: "max-content",
        fontWeight: 700,
        backgroundColor: "#1E88E5",
        color: "white",
        "&:hover": {
          backgroundColor: "#005cb2",
          boxShadow: "none",
        },
        boxShadow: "none",
      },
      containedSecondary: {
        minWidth: "max-content",
        fontWeight: 700,
        backgroundColor: "#E9F3FC",
        color: "#1E88E5",
        "&:hover": {
          backgroundColor: "#d7ecfc",
          boxShadow: "none",
        },
        boxShadow: "none",
      },
    },
  },
};

export default themeDefaultObject;
