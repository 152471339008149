// @flow
import {
  GET_DICTIONARY_SAGA,
  SET_DICTIONARY,
} from "../../helpers/constants";
import type { DropDownObjType, Dictionaries } from "../../reducers/dictionaries";

type Params = {
  filter?: string,
  performer?: string,
}

export type GetDictionarySaga = {|
  type: typeof GET_DICTIONARY_SAGA,
  dictionary: Dictionaries,
  filter?: string,
  performer?: string,
|}

export type SetDictionary = {|
  type: typeof SET_DICTIONARY,
  payload: DropDownObjType,
|}

export type Actions = SetDictionary
  | GetDictionarySaga;

export const setDictionary: (payload: DropDownObjType) => SetDictionary = (payload) => ({
  type: SET_DICTIONARY,
  payload,
});

export function getDictionarySaga(dictionary: Dictionaries, params: Params = {}): GetDictionarySaga {
  return {
    type: GET_DICTIONARY_SAGA,
    dictionary,
    performer: params.performer,
    filter: params.filter,
  };
}
