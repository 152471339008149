// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import {
  Box, Collapse, Divider, Paper, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

type Props = {
  title: string,
  children: Node,
}
const useStyles = makeStyles(() => ({
  collapseTitle: {
    cursor: "pointer",
  },
}));

const CollapseCard: StatelessFunctionalComponent<Props> = ({ title, children }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Paper>
      <Box onClick={handleClick} className={classes.collapseTitle}>
        <Box px={3} py={1} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{title}</Typography>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </Box>
      <Collapse in={open}>
        <Divider />
        {children}
      </Collapse>
    </Paper>
  );
};

export default CollapseCard;
