// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import {
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import type {
  TablePaginationClasses,
  TablePaginationProps,
} from "./types/TableComponent.types";

const useStyles: () => TablePaginationClasses = makeStyles((): TablePaginationClasses => ({
  pagination: {},
}));

const defaultRowPerPageOptions: number[] = [15, 25, 50, 100];

const Pagination: StatelessFunctionalComponent<TablePaginationProps> = ({
  page,
  pageSize,
  count,
  onChangePage,
  onChangePageSize,
  rowsPerPageOptions = defaultRowPerPageOptions,
}: TablePaginationProps) => {
  const classes: TablePaginationClasses = useStyles();
  const currentPage: number = page - 1;

  const handleChangePage: (SyntheticMouseEvent<HTMLButtonElement>, number) => void = (event, newPage) => {
    onChangePage(newPage + 1);
  };

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      className={classes.pagination}
      component="div"
      count={count}
      rowsPerPage={pageSize}
      page={currentPage}
      onPageChange={handleChangePage}
      onRowsPerPageChange={onChangePageSize && (({ target: { value } }) => onChangePageSize(value))}
    />
  );
};

export default Pagination;
