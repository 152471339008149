// @flow
import {
  List,
  type List as ListType,
} from "immutable";
import { createSelector } from "reselect";
import type { OutputSelector } from "reselect";
import type { State } from "../../store";

// eslint-disable-next-line import/prefer-default-export
export const getTransactionReportFields: OutputSelector<
  State,
  void,
  string[]
> = createSelector(
  (state: State): ListType<string> => state.transactionReport.get("fields", List()),
  (value: ListType<string>): string[] => value.toArray()
);
