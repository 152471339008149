// @flow
import {
  DOWNLOAD_TRANSACTION_REPORT_SAGA,
  GET_TRANSACTION_REPORT_SAGA,
  SET_TRANSACTION_REPORT_FIELDS,
  SET_TRANSACTION_REPORT_IS_ADVANCED,
} from "../../helpers/constants";

export type GetTransactionReportDataSaga = {|
  type: typeof GET_TRANSACTION_REPORT_SAGA,
|}

export type DownloadTransactionReportSaga = {|
  type: typeof DOWNLOAD_TRANSACTION_REPORT_SAGA,
|}

export type SetTransactionReportIsAdvanced = {|
  type: typeof SET_TRANSACTION_REPORT_IS_ADVANCED,
  isAdvancedFilter: boolean,
|}

export type SetTransactionReportFields = {|
  type: typeof SET_TRANSACTION_REPORT_FIELDS,
  fields: string[],
|}

export type Actions = GetTransactionReportDataSaga
  | SetTransactionReportIsAdvanced
  | SetTransactionReportFields
  | DownloadTransactionReportSaga

export const getTransactionReportDataSaga: () => GetTransactionReportDataSaga = () => ({
  type: GET_TRANSACTION_REPORT_SAGA,
});

export const downloadTransactionReportSaga: () => DownloadTransactionReportSaga = () => ({
  type: DOWNLOAD_TRANSACTION_REPORT_SAGA,
});

export const setTransactionReportTableIsAdvanced: (
  isAdvancedFilter: boolean
) => SetTransactionReportIsAdvanced = (isAdvancedFilter) => ({
  type: SET_TRANSACTION_REPORT_IS_ADVANCED,
  isAdvancedFilter,
});

export const setTransactionReportTableFields: (
  fields: string[],
) => SetTransactionReportFields = (fields) => ({
  type: SET_TRANSACTION_REPORT_FIELDS,
  fields,
});
